import React, { FunctionComponent } from 'react';
import * as ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { store } from './Common/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from '@mui/material';
import theme from 'Theme';
import { FronteggProvider } from '@frontegg/react';
import commonConfig from 'Config';
import { I18nextProvider } from 'react-i18next';
import TranslationService from 'shared/services/translation.service';
import translations from 'shared/fixtures/translations';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { ProSidebarProvider } from 'react-pro-sidebar';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { machineRoutePrefix } from 'shared/fixtures/data/route-list.data';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Frontegg context options
const contextOptions = {
  baseUrl: commonConfig.fronteggBaseUrl,
  clientId: commonConfig.fronteggClientId,
};

// rendering app
const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(rootElement);

const AppProvider: FunctionComponent<BaseComponentProps> = ({ children }) => {
  const location = window.location.pathname;

  if (location.includes(machineRoutePrefix)) {
    return <BrowserRouter>{children}</BrowserRouter>;
  }

  return (
    <FronteggProvider
      authOptions={{
        keepSessionAlive: true,
      }}
      contextOptions={contextOptions}
      hostedLoginBox={true}
      entitlementsOptions={{
        enabled: true,
      }}
    >
      {children}
    </FronteggProvider>
  );
};

root.render(
  <>
    <I18nextProvider
      i18n={TranslationService.getTranslationManager(translations)}
    >
      <AppProvider>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <ProSidebarProvider>
              <App />
            </ProSidebarProvider>
          </ThemeProvider>
        </Provider>
      </AppProvider>
    </I18nextProvider>
    <ToastContainer />
  </>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
