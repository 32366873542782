import { Button, IconButton, Modal } from '@mui/material';
import { FunctionComponent, ReactNode } from 'react';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import OpusSvgIcon from '../IconComponents/OpusSvgIcon';

export interface BaseModalProps {
  handleClose: () => void;
  isOpen: boolean;
}

export interface CommonModalContainerProps
  extends BaseComponentProps,
    BaseModalProps {
  title: string;
  components?: {
    CancelButton?: JSX.Element;
    SubmitButton?: JSX.Element;
    TitleIcon?: JSX.Element;
  };
  rootClassName?: string;
}

export const CommonModalContainer: FunctionComponent<
  CommonModalContainerProps
> = ({ isOpen, handleClose, components, children, title, rootClassName }) => {
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      classes={{
        root: `common-modal-container ${rootClassName || ''}`,
      }}
    >
      <div className="common-modal-inner-container">
        <div className="common-modal-header">
          <div className="label-3 common-modal-header-title-container">
            {components?.TitleIcon ? (
              <div className="common-modal-header-title-icon">
                {components.TitleIcon}
              </div>
            ) : (
              <></>
            )}
            <h3 className="label-2 common-modal-header-title">{title}</h3>
          </div>
          <IconButton
            className="common-modal-header-close-button"
            onClick={handleClose}
          >
            <OpusSvgIcon
              type={SVG_ICON_TYPES.TIMES_SECONDARY_ICON}
              className="common-modal-header-close-icon"
            />
          </IconButton>
        </div>
        <div className="common-modal-body">{children}</div>
        {/* <div className="common-modal-footer">
          {components?.CancelButton || <Button>Cancel</Button>}
          {components?.SubmitButton || <Button>Create</Button>}
        </div> */}
      </div>
    </Modal>
  );
};
