import { createApi } from '@reduxjs/toolkit/query/react';
import axiosFetchBaseQuery from 'Common/utils/axios-base-query';
import commonConfig from 'Config';
import { AutocompleteOption } from '../../FindingDetails/store/api';

const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: axiosFetchBaseQuery({ baseUrl: commonConfig.bffBaseUrl }),
  endpoints: (builder) => ({
    getOperationalWidgets: builder.query({
      query: () => ({
        url: 'widgets/operational',
        method: 'GET',
      }),
    }),
    getStrategicWidgets: builder.query({
      query: () => ({
        url: 'widgets/strategic',
        method: 'GET',
      }),
    }),
    getWidgetDataById: builder.mutation({
      query: ({ id, searchBody }) => ({
        url: `widgets/${id}/data/search`,
        method: 'POST',
        body: searchBody,
      }),
    }),
    getBusinessUnitListWithMetadata: builder.query({
      query: () => ({
        url: 'business-units/metadata',
        method: 'GET',
      }),
    }),

    getActiveCampaigns: builder.mutation({
      query: (data) => ({
        url: 'campaigns/analytics',
        method: 'POST',
        body: data,
      }),
    }),

    getDashboardAnalytics: builder.mutation({
      query: (data) => ({
        url: 'findings/analytics/search',
        method: 'POST',
        body: data,
      }),
    }),
    getDashboardPostureAnalytics: builder.mutation({
      query: (data) => ({
        url: 'findings/analytics/accumulative/posture/search',
        method: 'POST',
        body: data,
      }),
    }),
    getDashboardInsightsAnalytics: builder.mutation({
      query: (data) => ({
        url: 'findings/analytics/accumulative/posture/insights',
        method: 'POST',
        body: data,
      }),
    }),
    getDashboardPostureSdlcAnalytics: builder.mutation({
      query: (data) => ({
        url: 'findings/analytics/accumulative/posture/risk-surface/search',
        method: 'POST',
        body: data,
      }),
    }),
    getDashboardAnalyticTrends: builder.mutation({
      query: (data) => ({
        url: 'findings/analytics/trends/search',
        method: 'POST',
        body: data,
      }),
    }),
    fetchDashboardFilterInformationForField: builder.mutation({
      query: (payload) => ({
        url: `config/filters/dashboard/${payload.field}`,
        method: 'POST',
        body: payload?.body,
      }),
      transformResponse: async (response: AutocompleteOption[]) => {
        return response.sort((a, b) =>
          (a.label || a.value).localeCompare(b.label || b.value)
        );
      },
    }),
    fetchComparativeDashboardWidgetData: builder.mutation({
      query: (payload) => ({
        url: `findings/analytics/accumulative/comparative/search`,
        method: 'POST',
        body: payload,
      }),
    }),
    fetchOperationalDashboardWidgetData: builder.mutation({
      query: (payload) => ({
        url: `findings/analytics/accumulative/operational/search`,
        method: 'POST',
        body: payload,
      }),
    }),
    getUserAvailableNodesOfType: builder.mutation({
      query: (payload) => ({
        url: `organization-structures/getUserAvailableNodesOfType`,
        method: 'POST',
        body: payload,
      }),
    }),
    getScoreCardWidgetData: builder.mutation({
      query: (payload) => ({
        url: `findings/analytics/uniquetable/search`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export default dashboardApi;

export const {
  useGetOperationalWidgetsQuery,
  useGetStrategicWidgetsQuery,
  useGetWidgetDataByIdMutation,
  useGetBusinessUnitListWithMetadataQuery,
  useGetActiveCampaignsMutation,
  useGetDashboardAnalyticsMutation,
  useGetDashboardPostureAnalyticsMutation,
  useGetDashboardInsightsAnalyticsMutation,
  useFetchDashboardFilterInformationForFieldMutation,
  useFetchComparativeDashboardWidgetDataMutation,
  useFetchOperationalDashboardWidgetDataMutation,
  useGetDashboardAnalyticTrendsMutation,
  useGetUserAvailableNodesOfTypeMutation,
  useGetDashboardPostureSdlcAnalyticsMutation,
  useGetScoreCardWidgetDataMutation,
} = dashboardApi;
