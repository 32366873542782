import { FunctionComponent, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import WidgetContainer from 'Common/components/Widgets/components/WidgetContainer';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import WidgetBlock from './WidgetBlock';
import { insightsData } from 'Dashboard/interfaces/InsightTypes.enum';
import useCommonSelector from 'Common/utils/use-selector';
import {
  getFilterState,
  getFindingTypes,
  getInitialFilterLoad,
  selectdashboardFilter,
} from 'Dashboard/store';
import { PostureWidgetAnalyticsType } from 'shared/models/data/operational-widget-data.model';
import { title } from 'process';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { useGetDashboardInsightsAnalyticsMutation } from 'Dashboard/store/api';

interface TopInsightsWidgetProps extends BaseComponentProps {}

export interface InsightWidgetData {
  type: PostureWidgetAnalyticsType;
  isLoading: boolean;
  total: number;
}

export const TopInsightsWidget: FunctionComponent<
  TopInsightsWidgetProps
> = () => {
  const { t: translation } = useTranslation();
  const dashboardFilterInitialLoad = useCommonSelector(getInitialFilterLoad);
  const filterState = useCommonSelector(getFilterState);

  const filter = useCommonSelector(selectdashboardFilter);

  const findingTypes = useMemo(
    () => getFindingTypes(filterState),
    [filterState.findingType]
  );

  const [
    getInsightsAnalytics,
    { data: insightAnalytics, isLoading: loadingInsights },
  ] = useGetDashboardInsightsAnalyticsMutation();

  useEffect(() => {
    if (dashboardFilterInitialLoad) {
      getInsightsAnalytics({
        businessUnitIds: filter.businessUnitId,
        findingTypes,
      });
    }
  }, [dashboardFilterInitialLoad, filter.businessUnitId, findingTypes]);

  const renderedWidgets = insightsData.map((insight, index) => (
    <WidgetBlock
      type={insight.type}
      key={index}
      data={
        insightAnalytics
          ? insightAnalytics[insight.type]
          : {
              value: 0,
            }
      }
    />
  ));

  const renderLoadingWidget = () => {
    if (!loadingInsights) return <></>;
    return (
      <div className="empty-widget-container">
        <div className={`loading-icon icon`}>
          <OpusSvgIcon type={SVG_ICON_TYPES.LOADER_ICON} />
        </div>
        <p className="title">
          {translation(`dashboards.widgets.topInsights.loadingTitle`)}
        </p>
        <p className="description">
          {translation(`dashboards.widgets.topInsights.loadingData`)}
        </p>
      </div>
    );
  };

  const renderInsights = () => {
    if (loadingInsights) return renderLoadingWidget();

    if (insightAnalytics) {
      const hasInsights: boolean = Object.values(insightAnalytics).some(
        (insightAnalytic: any) => insightAnalytic.value > 0
      );

      if (hasInsights) {
        return (
          <div
            className="insights-widget-container"
            style={{ display: loadingInsights ? 'none' : 'grid' }}
          >
            {renderedWidgets}
          </div>
        );
      } else {
        return (
          <div className="empty-widget-container">
            <div className={`icon `}>
              <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_CHECK_ICON} />
            </div>
            <p className="title">
              {translation(`dashboards.widgets.topInsights.allClear`)}!
            </p>
            <p className="description">
              {translation(`dashboards.widgets.topInsights.noData`)}
            </p>
          </div>
        );
      }
    }

    return renderLoadingWidget();
  };

  return (
    <Grid className="top-insights-widget dashboard-table-widget">
      <WidgetContainer
        title={translation(`dashboards.widgets.topInsights.title`)}
      >
        {renderInsights()}
      </WidgetContainer>
    </Grid>
  );
};

export default TopInsightsWidget;
