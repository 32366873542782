import React, {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Autocomplete as MuiAutocomplete,
  Button,
  Chip,
  CircularProgress,
  Paper,
  Stack,
  TextField,
  Typography,
  IconButton,
} from '@mui/material';
import {
  AutocompleteClasses,
  createFilterOptions,
} from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { AutocompleteOption } from 'FindingDetails/store/api';
import CommonIconMui from '../IconMui';
import {
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete/Autocomplete';
import { useTranslation } from 'react-i18next';
import { useFetchFilterByTypeMutation } from 'shared/store/api';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { CommonCheckbox } from 'shared/components/CommonCheckbox/CommonCheckbox';

const VirtualizedOptionList = React.forwardRef<HTMLDivElement, any>(
  (props, ref) => {
    const { children, ...other } = props;
    const itemSize = 48;
    const itemCount = React.Children.count(children);
    const listRef = React.useRef<VariableSizeList>(null);

    const outerElementType = React.useMemo(() => {
      return React.forwardRef<HTMLDivElement>((props, ref) => (
        <div
          ref={ref}
          {...props}
          onMouseDown={(e) => {
            e.preventDefault();
          }}
        />
      ));
    }, []);

    useEffect(() => {
      if (listRef.current) {
        listRef.current.resetAfterIndex(0);
      }
    }, [itemCount]);

    const renderRow = (props: ListChildComponentProps) => {
      const { index, style } = props;
      return (
        <div
          style={{
            ...style,
            marginBottom: 8,
          }}
        >
          {React.Children.toArray(children)[index]}
        </div>
      );
    };

    return (
      <div {...other}>
        <VariableSizeList
          ref={listRef}
          width="100%"
          height={Math.min(itemCount * itemSize, 300)}
          itemCount={itemCount}
          itemSize={() => itemSize}
          overscanCount={5}
          outerElementType={outerElementType}
          className="auto-complete-virtual-list-box"
        >
          {renderRow}
        </VariableSizeList>
      </div>
    );
  }
);

interface AutocompleteProps {
  label?: string;
  model: string;
  onChangeCallBack: (
    model: string,
    value: Array<AutocompleteOption> | AutocompleteOption
  ) => void;
  onOpen?: () => void;
  mapper?: (item: any) => any;
  getIcon?: (option: AutocompleteOption) => React.ReactElement;
  initialSelectedValues?: AutocompleteOption[] | AutocompleteOption;
  optionList?: AutocompleteOption[] | null;
  url?: string;
  single?: boolean;
  classes?: Partial<AutocompleteClasses> & { label?: string };
  urlPrefix?: string;
  placeholder?: string;
  values?: AutocompleteOption[] | AutocompleteOption;
  icons?: {
    ChipRemoveIcon?: any;
    DropdownIcon?: any;
  };
  enableCheckbox?: boolean;
  displayAllTags?: boolean;
  freeSolo?: boolean;
  limitTags?: number;
  areOptionsLoaded?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  getOptionDisabled?: (option: any) => boolean;
  loading?: boolean;
  loadingText?: string;
  onSearch?: (searchKey: string) => void;
  id?: string;
  disableDropdown?: boolean;
  onClear?: () => void;
  enableAllSelection?: boolean;
  enableVirtualization?: boolean;
}

const CustomAutocomplete = styled(MuiAutocomplete)({
  '&': {
    width: '100%',
    marginTop: '0 !important',
    '& .MuiAutocomplete-tag': {
      backgroundColor: '#FFFFFF',
      border: '1px solid #CDD7E2',
      borderRadius: '5px',
      color: '#000000',
      height: '32px',
      display: 'inline-flex',
      alignItems: 'center',
      fontSize: 14,
      fontWeight: 500,
    },
    '& span.MuiAutocomplete-tag': {
      padding: '4px 8px',
    },
  },
});

const CustomOptionsPaper = styled(Paper)({
  '&': {
    borderRadius: '8px',
    background: '#FFFFFF',
    boxShadow:
      '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
  },
});

export const allOption = {
  value: 'All',
  label: 'All',
};

export default function Autocomplete({
  label,
  model,
  onChangeCallBack,
  mapper,
  initialSelectedValues = [],
  getIcon,
  optionList = null,
  url,
  single = false,
  classes = {},
  urlPrefix = '',
  placeholder = '',
  onOpen,
  values,
  icons,
  enableCheckbox = false,
  displayAllTags = false,
  freeSolo = false,
  limitTags = 2,
  areOptionsLoaded = false,
  disabled = false,
  readonly = false,
  loadingText,
  getOptionDisabled,
  loading,
  onSearch,
  id,
  disableDropdown,
  onClear,
  enableAllSelection,
  enableVirtualization,
}: AutocompleteProps) {
  const { t: translation } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<AutocompleteOption[]>([]);

  const [inputValue, setInputValue] = useState<string>(() => {
    if (single) {
      const singleValue = values as AutocompleteOption;

      return singleValue?.value || '';
    }

    return '';
  });

  const handleSelectAll = (checked: boolean) => {
    if (!single) {
      const selectableOptions = options.filter(
        (option) => !(getOptionDisabled && getOptionDisabled(option))
      );
      const newSelectedValues = checked ? selectableOptions : [];
      setSelectedValues(newSelectedValues);
      onChangeCallBack(model, newSelectedValues);
    }
  };

  useEffect(() => {
    if (single && onSearch) {
      const singleValue = values as AutocompleteOption;

      setInputValue(singleValue?.value || '');
    }
  }, [values]);

  let textFieldRef = useRef<HTMLInputElement>(null);

  const itemMapper = (item: AutocompleteOption) => {
    if (!mapper) return item;
    return {
      ...item,
      label: mapper(item.label ? item.label : item.value),
    };
  };
  const [fetchUniqueOptions, { data: uniqueOptions, isLoading }] =
    useFetchFilterByTypeMutation();

  useEffect(() => {
    if (
      isOpen &&
      !optionList?.length &&
      !uniqueOptions?.length &&
      !areOptionsLoaded
    ) {
      fetchUniqueOptions({
        type: urlPrefix + (url || model),
      });
    }
  }, [optionList, isOpen]);

  let initialItems = useMemo(() => {
    return mapper && Array.isArray(initialSelectedValues)
      ? initialSelectedValues?.map(itemMapper)
      : initialSelectedValues;
  }, [initialSelectedValues]);

  const [selectedValues, setSelectedValues] = React.useState<any>(initialItems);

  useEffect(() => {
    setSelectedValues(values);
  }, [values]);

  const handleOpen = () => {
    setIsOpen(true);

    onOpen && onOpen();
  };

  const setData = (options: AutocompleteOption[]) => {
    let newData = options;
    if (mapper) {
      newData = options.map(itemMapper);
    }

    setOptions(newData);
    return newData;
  };

  useEffect(() => {
    let newData;

    if (optionList) {
      newData = setData(optionList);
    }

    if (uniqueOptions && !optionList) {
      newData = setData(uniqueOptions);
    }
  }, [uniqueOptions, optionList]);

  const onChange = (
    selectedValues: AutocompleteOption[] | AutocompleteOption
  ) => {
    const selectedValueList = selectedValues as Array<AutocompleteOption>;

    const updatedSelectedValues = selectedValueList.length
      ? selectedValueList.map((selectedValue) => {
          if (typeof selectedValue === 'string') {
            return {
              value: selectedValue,
              label: selectedValue,
            };
          }

          return selectedValue;
        })
      : selectedValueList;

    setSelectedValues(updatedSelectedValues);
    onChangeCallBack(model, updatedSelectedValues);

    if (single) {
      setIsOpen(false);

      if (onSearch) {
        const selectedOption = selectedValues as AutocompleteOption;
        setInputValue(selectedOption.label || selectedOption.value);
      }

      if (textFieldRef.current) {
        (textFieldRef.current as HTMLInputElement).blur();
      }
    }
  };

  useEffect(() => {
    let initialItems =
      mapper && Array.isArray(initialSelectedValues)
        ? initialSelectedValues?.map(itemMapper)
        : initialSelectedValues;
    setSelectedValues(initialItems);
  }, []);

  const renderInput = (params: AutocompleteRenderInputParams) => {
    return (
      <TextField
        {...params}
        placeholder={placeholder}
        className="auto-complete-input"
        title={
          !Array.isArray(selectedValues)
            ? selectedValues?.label || selectedValues?.value
            : ''
        }
        disabled={disabled}
        inputRef={textFieldRef}
        InputProps={{
          ...params.InputProps,
          readOnly: readonly,
          ...(single && selectedValues && getIcon
            ? { startAdornment: getIcon(selectedValues as AutocompleteOption) }
            : {}),
          endAdornment: (
            <div className="auto-complete-end-adornment">
              {single && selectedValues && onClear ? (
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();

                    onClear && onClear();

                    setSelectedValues([]);
                  }}
                >
                  <OpusSvgIcon
                    onClick={() => {
                      setInputValue('');
                      onClear && onClear();
                    }}
                    className="auto-complete-clear-icon"
                    type={SVG_ICON_TYPES.CIRCLE_TIMES_ICON}
                  />
                </IconButton>
              ) : (
                <> </>
              )}
              {isLoading ? (
                <CircularProgress color="inherit" size={20} />
              ) : null}
              <Button
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
                className="auto-complete-expand-button"
                disabled={disabled}
              >
                {icons?.DropdownIcon || (
                  <OpusSvgIcon
                    type={
                      isOpen
                        ? SVG_ICON_TYPES.CHEVRON_UP_ICON
                        : SVG_ICON_TYPES.CHEVRON_DOWN_ICON
                    }
                  />
                )}
              </Button>
            </div>
          ),
        }}
        inputProps={{
          ...params.inputProps,
          onChange: (event: ChangeEvent<HTMLInputElement>) => {
            if (onSearch) {
              const searchKey = event.target.value;
              onSearch(searchKey);
              setInputValue(searchKey);
              params.inputProps.onChange && params.inputProps.onChange(event);
            } else {
              params.inputProps.onChange && params.inputProps.onChange(event);
            }
          },
          value: onSearch ? inputValue : params.inputProps.value,
        }}
        onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Backspace' && onSearch && inputValue.length > 0) {
            event.stopPropagation();
          }
        }}
      />
    );
  };

  const renderTags = (
    tagValue: unknown[],
    getTagProps: AutocompleteRenderGetTagProps
  ) => {
    if (displayAllTags) {
      return (
        <>
          {tagValue.map((option, index) => {
            const displayedOption =
              typeof option === 'string'
                ? {
                    value: option,
                    label: option,
                  }
                : (option as AutocompleteOption);
            return (
              <Chip
                {...getTagProps({ index })}
                label={
                  displayedOption.label
                    ? displayedOption.label
                    : displayedOption.value
                }
                title={
                  displayedOption.label
                    ? displayedOption.label
                    : displayedOption.value
                }
                avatar={
                  getIcon ? (
                    getIcon(option as AutocompleteOption)
                  ) : (
                    <React.Fragment />
                  )
                }
                deleteIcon={
                  icons?.ChipRemoveIcon || (
                    <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_TIMES_ICON} />
                  )
                }
              />
            );
          })}
        </>
      );
    }
    return (
      <>
        {tagValue.slice(0, limitTags).map((option, index) => {
          const displayedOption =
            typeof option === 'string'
              ? {
                  value: option,
                  label: option,
                }
              : (option as AutocompleteOption);
          return (
            <Chip
              {...getTagProps({ index })}
              label={
                displayedOption.label
                  ? displayedOption.label
                  : displayedOption.value
              }
              title={
                displayedOption.label
                  ? displayedOption.label
                  : displayedOption.value
              }
              avatar={
                getIcon ? (
                  getIcon(option as AutocompleteOption)
                ) : (
                  <React.Fragment />
                )
              }
              deleteIcon={
                icons?.ChipRemoveIcon || (
                  <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_TIMES_ICON} />
                )
              }
            />
          );
        })}
        {tagValue.length > limitTags && (
          <Chip
            {...getTagProps({ index: 1 })}
            label={`${tagValue.length - limitTags}+`}
            deleteIcon={
              icons?.ChipRemoveIcon || (
                <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_TIMES_ICON} />
              )
            }
          />
        )}
      </>
    );
  };

  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: unknown,
    { selected }: { selected: boolean }
  ) => {
    const displayedOption = option as AutocompleteOption;
    const disabled = getOptionDisabled ? getOptionDisabled(option) : false;
    const label = displayedOption.label
      ? displayedOption.label
      : displayedOption.value;

    if (displayedOption.value === allOption.value && enableAllSelection) {
      const allSelected = options.length === selectedValues?.length;
      const someSelected = selectedValues?.length > 0;

      return (
        <div>
          <li
            {...props}
            className={`auto-complete-option-box ${
              allSelected ? 'auto-complete-selected-option' : ''
            }  select-all-option`}
            onClick={() => {
              handleSelectAll(!allSelected);
            }}
          >
            <CommonCheckbox
              checked={allSelected}
              indeterminate={someSelected && !allSelected}
            />
            <Typography>{allOption.label}</Typography>
          </li>
        </div>
      );
    }

    return disabled ? (
      <div>
        <li
          className={`auto-complete-option-box 
          ${disabled ? 'auto-complete-option-box-disabled' : ''}`}
          title={label}
        >
          <Stack direction="row" spacing={1}>
            {getIcon && getIcon(displayedOption)}
            <Typography>{label}</Typography>
          </Stack>
          {selected && !enableCheckbox && (
            <CommonIconMui icon="done" size={20} color="#6664ED" />
          )}
        </li>
      </div>
    ) : (
      <li
        {...props}
        className={`auto-complete-option-box ${
          selected ? 'auto-complete-selected-option' : ''
        } 
        ${single ? 'auto-complete-option-box-single' : ''}`}
        title={label}
      >
        {enableCheckbox && !single ? (
          <CommonCheckbox checked={selected} />
        ) : (
          <></>
        )}
        <Stack direction="row" spacing={1}>
          {getIcon && getIcon(displayedOption)}
          <Typography>{label}</Typography>
        </Stack>
        {selected && !enableCheckbox && (
          <CommonIconMui icon="done" size={20} color="#6664ED" />
        )}
      </li>
    );
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    ignoreCase: true,
    stringify: (item: unknown) => {
      const option = item as AutocompleteOption;
      return option.label ? option.label : option.value || '';
    },
  });

  const getFilteredOptions = (filterOptions: any) => {
    if (single) return filterOptions;

    if (enableAllSelection) return [allOption, ...filterOptions];

    return filterOptions;
  };

  const disableSearch = (options: any) => {
    return options;
  };

  const getListBoxProps = () => {
    if (enableVirtualization) {
      return {
        ListboxComponent: VirtualizedOptionList,
      };
    }

    return {};
  };

  return (
    <div className="auto-complete-container">
      <Typography className={classes.label || 'auto-complete-label-select'}>
        {label}
      </Typography>
      <CustomAutocomplete
        disabled={disabled}
        limitTags={limitTags}
        multiple={!single}
        disableCloseOnSelect
        options={getFilteredOptions(options)}
        getOptionLabel={(option: any) => option?.label || ''}
        getOptionKey={(option: any) => option?.label + option?.value}
        open={disableDropdown ? false : isOpen}
        openOnFocus={true}
        onOpen={handleOpen}
        onClose={() => setIsOpen(false)}
        PaperComponent={CustomOptionsPaper}
        {...getListBoxProps()}
        value={
          single ? selectedValues : selectedValues?.length ? selectedValues : []
        }
        isOptionEqualToValue={(option: any, value: any) => {
          return option?.value === value?.value;
        }}
        onChange={(event: any, newSelectedValues: any) => {
          if (!single) {
            const filteredValues = newSelectedValues.filter(
              (value: AutocompleteOption) => value.value !== allOption.value
            );
            onChange(filteredValues);
          } else {
            onChange(newSelectedValues);
          }
        }}
        filterOptions={onSearch ? disableSearch : filterOptions}
        renderInput={renderInput}
        renderTags={renderTags}
        renderOption={renderOption}
        noOptionsText={translation('common.noOptions')}
        classes={{
          ...({
            ...classes,
            root: `${classes.root || ''} ${
              readonly ? 'auto-complete-root-readonly' : ''
            }`,
          } || {}),
        }}
        getOptionDisabled={getOptionDisabled}
        freeSolo={freeSolo}
        readOnly={readonly}
        loading={loading}
        loadingText={loadingText}
        id={id}
        key={freeSolo ? 'free-solo' : 'base'}
      />
    </div>
  );
}
