import React, { FunctionComponent, useEffect, useState } from 'react';
import { Fab, Popover, IconButton, Tooltip } from '@mui/material';
import ChatBot, {
  Button,
  ChatBotProvider,
  useMessages,
  useToasts,
  useFlow,
  useChatHistory,
} from 'react-chatbotify';
import axios from 'axios';
import { useAuthUser } from '@frontegg/react';
import { useCreateChatDialogMutation } from 'FindingDetails/store/api';
import { Refresh } from '@mui/icons-material';
import _ from 'lodash';
import ChatFindingsGrid from './components/ChatFindingsGrid';
import { FindingsWithResource } from 'FindingDetails/interfaces/FindingsWithResources';

const extractFindingsListFromChatMessage = (
  inputHtml: string
): Array<FindingsWithResource> => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = inputHtml;

  const hiddenDiv = tempDiv.querySelector('.chat-findings-list');

  if (hiddenDiv) {
    const textContent = hiddenDiv.textContent?.trim();

    try {
      const jsonData = JSON.parse(textContent || '');

      return jsonData;
    } catch (error) {
      return [];
    }
  } else {
    return [];
  }
};

interface CostumChatBotProps {}

const CostumChatBot: FunctionComponent<CostumChatBotProps> = ({}) => {
  let hasError = false;

  const user = useAuthUser();

  const { showToast } = useToasts();

  const { replaceMessages, injectMessage } = useMessages();

  const { restartFlow } = useFlow();

  const { setHistoryMessages } = useChatHistory();

  const [loadingHistory, setLoadingHistory] = useState<boolean>(true);

  const [
    createChatDialog,
    {
      data: createChatDialogData,
      isLoading: createChatDialogLoading,
      isSuccess: createChatDialogSuccess,
    },
  ] = useCreateChatDialogMutation();

  useEffect(() => {
    if (createChatDialogLoading) {
      replaceMessages([]);
      showToast('Starting conversation...');
    }
  }, [createChatDialogLoading]);

  const dialogId =
    createChatDialogData?.dialog_id || localStorage.getItem('dialogId');

  useEffect(() => {
    if (!dialogId) {
      setLoadingHistory(false);
      setHistoryMessages([]);
      createChatDialog('');
    } else {
      const chatHistoryString = localStorage.getItem(`rcb-history`);

      try {
        const chatHistory = JSON.parse(chatHistoryString || '') || [];
        showToast('Loading conversation history...', 1000);

        localStorage.setItem('rcb-history', JSON.stringify([]));

        setTimeout(() => {
          setLoadingHistory(false);
          for (const chatHistoryMessage of chatHistory) {
            if (chatHistoryMessage.type === 'object') {
              const findignsList = extractFindingsListFromChatMessage(
                chatHistoryMessage.content
              );

              injectMessage(
                <ChatFindingsGrid findingList={findignsList} />,
                chatHistoryMessage.sender
              );
            } else {
              injectMessage(
                chatHistoryMessage.content,
                chatHistoryMessage.sender
              );
            }
          }
        }, 1000);
      } catch (err) {
        setLoadingHistory(false);
      }
    }
  }, []);

  useEffect(() => {
    if (createChatDialogSuccess) {
      localStorage.setItem('dialogId', createChatDialogData?.dialog_id);
      restartFlow();
    }
  }, [createChatDialogSuccess]);

  const call_chat = async (params: any) => {
    try {
      const chatCompletion = await axios.post(
        `${process.env.REACT_APP_BFF_BASE_URL}/findings/chat`,
        {
          message: params.userInput,
          dialogId: localStorage.getItem('dialogId'),
        },
        {
          headers: {
            Authorization: `${localStorage.getItem('ACCESS_TOKEN')}`,
          },
        }
      );

      await params.injectMessage(chatCompletion.data?.text);

      if (chatCompletion.data?.findings?.findings?.length)
        await params.injectMessage(
          <ChatFindingsGrid
            findingList={chatCompletion.data?.findings?.findings}
          />
        );
    } catch (error) {
      await params.injectMessage(
        'Unable to load model, is your API Key valid?'
      );
      hasError = true;
    }
  };

  const emptyBlock = {
    message: '',
    path: 'loop',
  };

  const refreshConversation = () => {
    localStorage.removeItem('dialogId');
    replaceMessages([]);
    setHistoryMessages([]);
    createChatDialog('');
  };

  const flow = {
    start:
      createChatDialogLoading || loadingHistory
        ? emptyBlock
        : {
            message: () => `Hello ${user.name}! Ask me a question.`,
            path: 'loop',
          },
    loop: {
      message: async (params: any) => {
        await call_chat(params);
      },
      path: () => {
        return 'loop';
      },
    },
  };

  return (
    <ChatBot
      settings={{
        general: { embedded: true, showFooter: false },
        header: {
          title: 'RemedyAI',
          avatar:
            'https://res.cloudinary.com/opus-security/image/upload/v1737732123/platform-general-icons-svg/chat-bot-icon.svg',
          buttons: [
            <Tooltip title="Refresh the conversation">
              <IconButton
                className="chat-refresh-button"
                onClick={() => refreshConversation()}
              >
                <Refresh
                  style={{
                    color: 'white',
                  }}
                />
              </IconButton>
            </Tooltip>,
            Button.NOTIFICATION_BUTTON,
          ],
        },
        notification: {
          disabled: true,
        },
        chatInput: {
          disabled: createChatDialogLoading,
          sendButtonIcon:
            'https://res.cloudinary.com/opus-security/image/upload/v1738062843/platform-general-icons-svg/paper-plane-top-white.svg',
        },
        botBubble: {
          dangerouslySetInnerHtml: true,
        },
        chatWindow: {
          autoJumpToBottom: false,
        },
      }}
      key="opus-chat-bot"
      flow={flow}
      id="opus-chat-bot"
    />
  );
};

export const ChatWithBubble = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleBubbleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const isPopoverOpen = Boolean(anchorEl);

  return (
    <div>
      <Fab
        color="primary"
        aria-label="chat"
        onClick={handleBubbleClick}
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 1000,
        }}
      >
        <img src="https://res.cloudinary.com/opus-security/image/upload/v1737732123/platform-general-icons-svg/chat-bot-icon.svg" />
      </Fab>

      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            width: '70vw',
            height: '90vh',
            marginBottom: '1rem',
            boxShadow: 'none',
            border: 'none',
            background: 'transparent',
          },
        }}
      >
        <ChatBotProvider>
          <CostumChatBot />
        </ChatBotProvider>
      </Popover>
    </div>
  );
};
