import { Box, Link, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

export interface TicketNameProps extends BaseComponentProps {
  logo: string;
  ticketId: string;
  link: string;
  title: string;
}

export const TicketName: FunctionComponent<TicketNameProps> = ({
  logo,
  ticketId,
  title,
  link,
}) => {
  return (
    <div className="ticket-name-container">
      <div className="ticket-icon-container">
        <img src={logo} height={18} />
      </div>
      <div className="ticket-details">
        <a href={link} className="title" target="_blank">
          {title}
        </a>
        <p className="id">{ticketId}</p>
      </div>
    </div>
  );
};
