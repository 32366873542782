import { AutocompleteOption } from 'FindingDetails/store/api';

export const dateTimeFormat = 'MMMM DD, YYYY [at] hh:mm A';
export class DateService {
  addWeeks(date: Date, weeks: number): Date {
    date.setDate(date.getDate() + 7 * weeks);
    return date;
  }

  addMonths(date: Date, months: number): Date {
    return new Date(date.setMonth(date.getMonth() + months));
  }

  diffInDays(startDate: Date, endDate: Date): number {
    const startUTC = Date.UTC(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate()
    );
    const endUTC = Date.UTC(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate()
    );
    const millisecondsInADay = 1000 * 60 * 60 * 24;
    return Math.floor((endUTC - startUTC) / millisecondsInADay);
  }

  diffInMonths(startDate: Date, endDate: Date): number {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const yearDifference = end.getFullYear() - start.getFullYear();
    const monthDifference = end.getMonth() - start.getMonth();

    // Calculate the total number of months between the two dates
    const totalMonthDifference = yearDifference * 12 + monthDifference;

    return totalMonthDifference;
  }

  convertTimeTo24Hour(value: string): number {
    const timeMatch = value.match(/^(\d{1,2}):(\d{2})\s*(AM|PM)$/i);

    if (!timeMatch) {
      throw new Error("Invalid time format. Expected format: 'HH:MM AM/PM'");
    }

    const [, hours, minutes, period] = timeMatch;
    let hourIn24 = parseInt(hours, 10);

    if (period.toUpperCase() === 'PM' && hourIn24 !== 12) {
      hourIn24 += 12;
    } else if (period.toUpperCase() === 'AM' && hourIn24 === 12) {
      hourIn24 = 0;
    }

    return hourIn24;
  }
  convertHourTo12HourFormat(hour: number): string {
    if (hour < 0 || hour > 23) {
      throw new Error('Hour must be between 0 and 23');
    }

    const period = hour < 12 ? 'AM' : 'PM';
    const adjustedHour = hour % 12 === 0 ? 12 : hour % 12;
    const formattedHour = adjustedHour.toString().padStart(2, '0');
    const formattedTime = `${formattedHour}:00 ${period}`;

    return formattedTime;
  }
  generateTimeOptions = (): AutocompleteOption[] => {
    const options: AutocompleteOption[] = [];
    const periods = ['AM', 'PM'];

    for (let i = 0; i < 24; i++) {
      const hour = i % 12 === 0 ? 12 : i % 12;
      const period = periods[Math.floor(i / 12)];
      const formattedHour = hour.toString().padStart(2, '0');

      options.push({
        label: `${formattedHour}:00 ${period}`,
        value: `${formattedHour}:00 ${period}`,
      });
    }

    return options;
  };
}
