import { useAuthUser } from '@frontegg/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Avatar, Button, FormControlLabel, Skeleton } from '@mui/material';
import { useCreateCampaignMutation } from 'Campaigns/store/api';
import Autocomplete from 'Common/components/Autocomplete';
import CommonTooltip from 'Common/components/CommonTooltip/CommonTooltip';
import DatePicker from 'Common/components/DatePicker';
import useCommonSelector from 'Common/utils/use-selector';
import { AvailableUser } from 'FindingDetails/interfaces/Users';
import {
  AutocompleteOption,
  useFetchAvailableUsersToAssignMutation,
  useFetchFindingsWithResourceMutation,
} from 'FindingDetails/store/api';
import {
  getAdditionalSearchParams,
  getGridDuplicationState,
  getLabelSearchParams,
  getTagsSearchParams,
  getVulnerabilitySearchParams,
} from 'Risk/store';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CommonCheckbox from 'shared/components/CommonCheckbox';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import InputLabelWrapper from 'shared/components/InputLabelWrapper';
import { CampaignCreateFormDateRange } from 'shared/enums/campaigns.enum';
import {
  campaignCreateFormBusinessPriorityOptions,
  campaignCreateFormDateRangeOptions,
} from 'shared/fixtures/data/campaign-create-form.data';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import {
  CampaignCreateFormValues,
  CampaignCriticality,
  CampaignType,
  campaignFormSchema,
} from 'shared/models/data/campaigns.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { CampaignFormDateService } from 'shared/services/campaigns/campaign-form-date.service';
import moment from 'moment';

interface CampaignCreateFormProps extends BaseComponentProps {
  onCloseModal: () => void;
  onSubmit: (values: CampaignCreateFormValues) => void;
  campaignName?: string;
  selectedFindingIds?: Array<string>;
  additionalFilters?: Record<string, any>;
  disableLiveCampaign?: boolean;
}

type HookFormValueKey =
  | `${string}`
  | `${string}.${string}`
  | `${string}.${number}`;

const campaignFormDateService = new CampaignFormDateService();

export const CampaignCreateForm: FunctionComponent<CampaignCreateFormProps> = ({
  onCloseModal,
  onSubmit,
  campaignName,
  selectedFindingIds,
  additionalFilters,
  disableLiveCampaign,
}) => {
  const { t: translation } = useTranslation();

  const {
    register,
    handleSubmit: handleFormSubmit,
    watch: watchFormValue,
    setValue: setFormValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(campaignFormSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const additionalSearchParams = useCommonSelector(getAdditionalSearchParams);
  const vulnerabilitySearchParams = useCommonSelector(
    getVulnerabilitySearchParams
  );
  const tagsSearchParams = useCommonSelector(getTagsSearchParams);

  const labelSearchParams = useCommonSelector(getLabelSearchParams);

  const isDuplicatedStateEnabled = useCommonSelector(getGridDuplicationState);

  const [
    createCampaign,
    {
      isLoading: creatingCampaign,
      isSuccess: creatingCampaignSuccess,
      error: createCampaignError,
    },
  ] = useCreateCampaignMutation();

  const [
    fetchFindingsWithResource,
    { isLoading: findingsLoading, data: findingsWithResource },
  ] = useFetchFindingsWithResourceMutation();

  const ownerUser = watchFormValue('userOwnerId');
  const stakeholders = watchFormValue('stakeholders');

  const duration = watchFormValue('duration');

  const startTime = watchFormValue('startTime');
  const endDate = watchFormValue('endDate');

  const type = watchFormValue('type');

  const activeUser = useAuthUser();

  const navigate = useNavigate();

  const [fetchUserList, { data: userList, isLoading: userListLoading }] =
    useFetchAvailableUsersToAssignMutation();

  const userOptionList = useMemo<Array<AutocompleteOption>>(() => {
    if (userList) {
      return userList?.map((user: AvailableUser) => ({
        value: user.id,
        label: user.name,
        iconUrl: user.profilePictureUrl,
      }));
    }

    return [];
  }, [userList]);

  useEffect(() => {
    if (
      selectedFindingIds?.length === 0 ||
      (selectedFindingIds &&
        selectedFindingIds?.length > 0 &&
        Object.keys(additionalFilters || {}).length > 0) ||
      Object.keys(additionalFilters || {}).length > 0
    ) {
      fetchFindingsWithResource({
        filter: {
          ...additionalSearchParams,
          ...additionalFilters,
        },
        take: 1,
        skip: 0,
        vulnerabilityFilter: vulnerabilitySearchParams,
        tagFilter: tagsSearchParams,
        removeDuplications: isDuplicatedStateEnabled,
        labelFilter: labelSearchParams,
      });
    }
  }, [additionalFilters, selectedFindingIds]);

  useEffect(() => {
    fetchUserList({});

    setFormValue('priority', {
      value: CampaignCriticality.MBI,
      label: CampaignCriticality.MBI,
    });

    setFormValue('type', {
      value: CampaignType.STATIC,
      label: CampaignType.STATIC,
    });
  }, []);

  useEffect(() => {
    if (activeUser) {
      const userOption = {
        value: activeUser?.id,
        label: activeUser?.name,
        iconUrl: activeUser?.profilePictureUrl as string,
      };

      setFormValue('ownerUserId', userOption);
      setFormValue('stakeholders', [userOption]);
    }
  }, [activeUser]);

  useEffect(() => {
    if (creatingCampaignSuccess) {
      navigate('/campaigns');
    }
  }, [creatingCampaignSuccess]);

  const onFormSubmit = (values: any) => {
    const getDateRangeFunction =
      campaignFormDateService.getRangeCalculatorFunctionByOptions(
        values.duration?.value
      );

    const dateRange = getDateRangeFunction();

    const startTime =
      values.duration?.value === CampaignCreateFormDateRange.CUSTOM
        ? values.startTime
        : dateRange.startDate;

    const endDate =
      values.duration?.value === CampaignCreateFormDateRange.CUSTOM
        ? values.endDate
        : dateRange.endDate;

    createCampaign({
      ...values,
      ownerUserId: values.ownerUserId.value,
      priority: values.priority.value,
      stakeholders: values.stakeholders.map(
        (stakeholder: AutocompleteOption) => stakeholder.value
      ),
      type: values.type.value,
      appliedFilter:
        (!additionalFilters ||
          Object.keys(additionalFilters || {}).length === 0) &&
        selectedFindingIds?.length
          ? undefined
          : {
              filter: { ...additionalSearchParams, ...additionalFilters },
              vulnerabilityFilter: vulnerabilitySearchParams,
              tagFilter: tagsSearchParams,
              labelFilter: labelSearchParams,
              removeDuplications: isDuplicatedStateEnabled,
            },
      startTime: moment(startTime).startOf('day').toISOString(),
      endDate: moment(endDate).endOf('day').toISOString(),
      findingIds: selectedFindingIds,
    });
  };

  const findingCount = useMemo<number>(() => {
    if (findingsWithResource?.totalItems && selectedFindingIds?.length) {
      return findingsWithResource.totalItems + selectedFindingIds.length;
    }

    if (selectedFindingIds?.length) {
      return selectedFindingIds.length;
    }

    return findingsWithResource?.totalItems || 0;
  }, [findingsWithResource?.totalItems, selectedFindingIds]);

  const renderFindingsCount = () => {
    if (findingsLoading) return <Skeleton height={52} />;

    return (
      <div className="form-helper-text">
        <OpusSvgIcon type={SVG_ICON_TYPES.INFO_CIRCLE_ICON} />
        <span>
          You are about to create a campaign for <b>{findingCount}</b> findings
        </span>
      </div>
    );
  };

  const renderLiveWarning = () => {
    if (selectedFindingIds?.length && type?.value === CampaignType.LIVE) {
      return (
        <div className="campaign-create-modal-live-warning">
          Opus found some findings outside the specified filter, only future
          findings matching the filter will be added to the live campaign.
        </div>
      );
    }

    return <></>;
  };

  const renderErrorMessage = () => {
    if (createCampaignError) {
      return (
        <div className="validation-error-text">
          {(createCampaignError as any)?.data?.message}
        </div>
      );
    }

    return <></>;
  };

  return (
    <div className="campaign-create-modal-form-container">
      {renderFindingsCount()}
      <form className="campaign-create-modal-form">
        <InputLabelWrapper label="Campaign Title">
          <input
            type="text"
            className={`text-field-1 ${
              errors.name ? 'input-validation-error-1' : ''
            }`}
            {...register('name')}
          />
        </InputLabelWrapper>
        <InputLabelWrapper label="Campaign Owner">
          <Autocomplete
            single
            classes={{
              root: 'multi-select-field-1 ',
              paper: 'multi-select-field-paper-1',
              inputRoot: errors.ownerUserId ? 'input-validation-error-1' : '',
            }}
            model="ownerUserId"
            optionList={userOptionList}
            areOptionsLoaded
            getIcon={(option) => (
              <Avatar src={option.iconUrl} sx={{ width: 16, height: 16 }} />
            )}
            onChangeCallBack={(
              model: string,
              filterOptions: Array<AutocompleteOption> | AutocompleteOption
            ) => {
              setFormValue(model as HookFormValueKey, filterOptions);
            }}
            initialSelectedValues={{
              value: activeUser?.id,
              label: activeUser?.name,
              iconUrl: activeUser?.profilePictureUrl as string,
            }}
          />
        </InputLabelWrapper>
        <InputLabelWrapper label="Campaign Duration">
          <Autocomplete
            classes={{
              root: 'multi-select-field-1 ',
              paper: 'multi-select-field-paper-1',
              inputRoot: errors.duration ? 'input-validation-error-1' : '',
            }}
            model="duration"
            onChangeCallBack={(
              model: string,
              option: Array<AutocompleteOption> | AutocompleteOption
            ) => {
              setFormValue(model as HookFormValueKey, option);
            }}
            optionList={campaignCreateFormDateRangeOptions}
            single
            placeholder={translation(`campaigns.modal.placeholders.dateRange`)}
          />
        </InputLabelWrapper>
        {duration?.value === CampaignCreateFormDateRange.CUSTOM && (
          <>
            <InputLabelWrapper label="">
              <DatePicker
                label={'Start Date'}
                model="startTime"
                minDate={new Date()}
                onChangeCallBack={(model: string, values: Array<string>) => {
                  setFormValue(model as HookFormValueKey, values[0]);
                }}
                classes={{
                  root: 'campaign-create-form-date-picker',
                }}
                range={false}
                placeholder={translation(
                  `campaigns.modal.placeholders.startTime`
                )}
              />
            </InputLabelWrapper>

            <InputLabelWrapper label="">
              <DatePicker
                label={'End Date'}
                model="endDate"
                minDate={
                  startTime ? (startTime as unknown as Date) : new Date()
                }
                onChangeCallBack={(model: string, values: Array<string>) => {
                  setFormValue(model as HookFormValueKey, values[0]);
                }}
                classes={{
                  root: 'campaign-create-form-date-picker',
                }}
                range={false}
                placeholder={translation(
                  `campaigns.modal.placeholders.endDate`
                )}
              />
            </InputLabelWrapper>
          </>
        )}
        <InputLabelWrapper label="Campaign Assignees">
          <Autocomplete
            classes={{
              root: 'multi-select-field-1 ',
              paper: 'multi-select-field-paper-1',
              inputRoot: errors.stakeholders ? 'input-validation-error-1' : '',
            }}
            model="stakeholders"
            optionList={userOptionList}
            areOptionsLoaded
            getIcon={(option) => (
              <Avatar src={option.iconUrl} sx={{ width: 16, height: 16 }} />
            )}
            onChangeCallBack={(
              model: string,
              filterOptions: Array<AutocompleteOption> | AutocompleteOption
            ) => {
              setFormValue(model as HookFormValueKey, filterOptions);
            }}
            initialSelectedValues={[
              {
                value: activeUser?.id,
                label: activeUser?.name,
                iconUrl: activeUser?.profilePictureUrl as string,
              },
            ]}
            enableCheckbox
          />
        </InputLabelWrapper>
        <InputLabelWrapper label="Business Priority">
          <Autocomplete
            classes={{
              root: 'multi-select-field-1 ',
              paper: 'multi-select-field-paper-1',
              inputRoot: errors.priority ? 'input-validation-error-1' : '',
            }}
            model="priority"
            optionList={campaignCreateFormBusinessPriorityOptions}
            single
            onChangeCallBack={(
              model: string,
              option: Array<AutocompleteOption> | AutocompleteOption
            ) => {
              setFormValue(model as HookFormValueKey, option);
            }}
            initialSelectedValues={{
              value: CampaignCriticality.MBI,
              label: CampaignCriticality.MBI,
            }}
          />
        </InputLabelWrapper>
        <div className="campaign-create-modal-form-live">
          <FormControlLabel
            classes={{
              root: 'campaign-create-modal-form-live-label',
            }}
            disabled={disableLiveCampaign}
            control={
              <CommonCheckbox
                disabled={disableLiveCampaign}
                onChange={(event) => {
                  event.stopPropagation();

                  setFormValue(
                    'type',
                    event.target.checked
                      ? { value: CampaignType.LIVE, label: CampaignType.LIVE }
                      : {
                          value: CampaignType.STATIC,
                          label: CampaignType.STATIC,
                        }
                  );
                }}
              />
            }
            label={
              <div className="campaign-create-modal-form-live-info">
                <span>Live Campaign</span>
                <CommonTooltip
                  placement="top"
                  title={
                    <span className="campaign-live-tooltip-text">
                      All current and future findings under the applied filter
                      will be automatically added to the campaign
                    </span>
                  }
                >
                  <span>
                    <OpusSvgIcon type={SVG_ICON_TYPES.INFO_CIRCLE_ICON} />
                  </span>
                </CommonTooltip>
              </div>
            }
          />
        </div>
      </form>
      {renderLiveWarning()}
      {renderErrorMessage()}
      <div className="campaign-create-modal-form-footer">
        <div></div>
        <div className="campaign-create-modal-form-buttons">
          <Button onClick={onCloseModal} className="action-close">
            Cancel
          </Button>
          <Button
            className="action-save opus-primary-button"
            onClick={() => {
              handleFormSubmit(onFormSubmit)();
            }}
          >
            {creatingCampaign ? 'Creating' : 'Create'}
          </Button>
        </div>
      </div>
    </div>
  );
};
