import { Button, CircularProgress } from '@mui/material';
import { OrderState } from 'Common/utils/sort';
import useCommonDispatch from 'Common/utils/use-dispatch';
import useCommonSelector from 'Common/utils/use-selector';
import {
  getFindingsFilterState,
  setFindingsPage,
  setFindingsPageSize,
} from 'FindingDetails/store';
import { useFetchFindingsWithResourceMutation } from 'FindingDetails/store/api';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import {
  GridType,
  RiskFilterItem,
  getGridColumnsByGridType,
  getRiskSearchParams,
  getRiskTableSelectionProps,
  setGridColumns,
  setRiskTableSelection,
  getGridDuplicationState,
  getAdditionalSearchParams,
  getVulnerabilitySearchParams,
  getTagsSearchParams,
  getLabelSearchParams,
  getNotificationRulesHistorySearchParams,
} from 'Risk/store';
import { CommonSimpleDataGrid } from 'shared/components/CommonSimpleDataGrid/CommonSimpleDataGrid';
import NoDataBackdrop from 'shared/components/NoDataBackdrop';
import {
  defaultHiddenRiskGridColumns,
  getAllColumnDefs,
  getRiskSearchParamsRequestDependencies,
} from 'shared/fixtures/data/risk-grid.data';
import { FilterSearchParamsHandler } from 'shared/handlers/filter-search-params.handler';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import {
  ColumnMovedEvent,
  ColumnVisibleEvent,
  GetRowIdParams,
  RowClickedEvent,
} from 'ag-grid-community';
import { FindingsWithResource } from 'FindingDetails/interfaces/FindingsWithResources';

interface RiskGridProps extends BaseComponentProps {
  clearFilters: () => void;
  onRowClicked?: (event: RowClickedEvent) => void;
  gridRef?: any;
}

const riskSearchParamsHandler = new FilterSearchParamsHandler();

export const RiskGrid: FunctionComponent<RiskGridProps> = ({
  clearFilters,
  onRowClicked,
  gridRef,
}) => {
  let [urlSearchParams, setUrlSearchParams] = useQueryParams();

  let [findingDetailsModelOpen, setFindingDetailsModalOpen] = useState(false);
  const isDuplicatedStateEnabled = useCommonSelector(getGridDuplicationState);

  const orderParams = useMemo<OrderState | null>(() => {
    try {
      if (urlSearchParams.order) {
        const parsedOrderParams = JSON.parse(
          urlSearchParams.order as string
        ) as OrderState;

        return parsedOrderParams;
      }

      return null;
    } catch (err) {
      return null;
    }
  }, [urlSearchParams.order]);

  const dispatch = useCommonDispatch();

  const riskSearchParams = useCommonSelector(getRiskSearchParams);

  const findingsFilterState = useCommonSelector(getFindingsFilterState);
  const additionalSearchParams = useCommonSelector(getAdditionalSearchParams);
  const vulnerabilitySearchParams = useCommonSelector(
    getVulnerabilitySearchParams
  );
  const tagsSearchParams = useCommonSelector(getTagsSearchParams);
  const labelSearchParams = useCommonSelector(getLabelSearchParams);
  const notificationRuleHistorySearchParams = useCommonSelector(
    getNotificationRulesHistorySearchParams
  );

  const riskGridSelectionProps = useCommonSelector(getRiskTableSelectionProps);

  const noneRiskTableColumns = useCommonSelector(
    getGridColumnsByGridType(GridType.None)
  );

  const onHandleColumnOrderChange = (columnOrderPayload: Array<OrderState>) => {
    const existingParams = riskSearchParamsHandler.setSearchParamsForKeys(
      urlSearchParams,
      ['activeTabId', 'filter', 'searchKeyword']
    );
    if (columnOrderPayload.length) {
      setUrlSearchParams({
        ...existingParams,
        order: JSON.stringify({
          field: columnOrderPayload[0].field,
          type: columnOrderPayload[0].type,
        }),
      });
    } else {
      delete existingParams.order;

      setUrlSearchParams({
        ...existingParams,
      });
    }
  };

  const titleValue = useMemo<string>(() => {
    const titleRiskFilterItem = riskSearchParams?.filter
      ?.title as RiskFilterItem;
    return titleRiskFilterItem?.value;
  }, [riskSearchParams]);

  useEffect(() => {
    fetchFindingsWithResource({
      filter: {
        ...additionalSearchParams,
        ...riskSearchParams?.filter,
      } as any,
      removeDuplications: isDuplicatedStateEnabled,
      order: orderParams as OrderState,
      take: findingsFilterState.pageSize,
      skip:
        findingsFilterState.page && findingsFilterState.pageSize
          ? (findingsFilterState.page - 1) * findingsFilterState.pageSize
          : 0,
      vulnerabilityFilter: vulnerabilitySearchParams,
      tagFilter: tagsSearchParams,
      labelFilter: labelSearchParams,
      notificationRulesHistoryFilter: notificationRuleHistorySearchParams,
    });
  }, [
    ...getRiskSearchParamsRequestDependencies(additionalSearchParams),
    ...getRiskSearchParamsRequestDependencies(vulnerabilitySearchParams),
    ...getRiskSearchParamsRequestDependencies({
      tags: tagsSearchParams,
    }),
    ...getRiskSearchParamsRequestDependencies({
      labels: labelSearchParams,
    }),
    notificationRuleHistorySearchParams.executionGroupId,
    findingsFilterState.page,
    findingsFilterState.pageSize,
    titleValue,
    orderParams,
    isDuplicatedStateEnabled,
  ]);

  useEffect(() => {
    if (urlSearchParams.openFindingId && findingDetailsModelOpen === false) {
      setFindingDetailsModalOpen(true);
    }

    if (!urlSearchParams.openFindingId && findingDetailsModelOpen === true) {
      setFindingDetailsModalOpen(false);
    }
  }, [urlSearchParams.openFindingId]);

  const resetPageNumber = () => {
    if (findingsFilterState.page !== 1) dispatch(setFindingsPage(1));
  };

  useEffect(() => {
    resetPageNumber();
  }, [
    ...getRiskSearchParamsRequestDependencies(additionalSearchParams),
    ...getRiskSearchParamsRequestDependencies(vulnerabilitySearchParams),
    ...getRiskSearchParamsRequestDependencies({
      tags: tagsSearchParams,
    }),
    titleValue,
    orderParams,
    isDuplicatedStateEnabled,
  ]);

  const [fetchFindingsWithResource, { isLoading, data: findingsWithResource }] =
    useFetchFindingsWithResourceMutation();

  const onPageChange = (selectedPage: number) => {
    dispatch(setFindingsPage(selectedPage));
  };

  const onPageSizeChange = (selectedPageSize: number) => {
    dispatch(setFindingsPageSize(selectedPageSize));
  };

  const onHandleSelectionChange = (selections: Array<string>) => {
    dispatch(
      setRiskTableSelection({
        ...riskGridSelectionProps,
        selectedChildren: selections,
        totalSelectedChildren: selections.length,
      })
    );
  };

  const getUniqueFindings = (
    findingWithResources: Array<FindingsWithResource>
  ) => {
    return findingWithResources?.filter((item, index, array) => {
      return (
        index ===
        array.findIndex((arrayItem) => arrayItem.findingId === item.findingId)
      );
    });
  };

  return (
    <>
      <CommonSimpleDataGrid
        rowData={getUniqueFindings(
          findingsWithResource?.data as Array<FindingsWithResource>
        )}
        columnDefs={noneRiskTableColumns}
        rowSelection="multiple"
        defaultColDef={{
          resizable: true,
          sortable: true,
        }}
        suppressRowClickSelection
        visibilityControlProps={{
          enableVisibilityControls: true,
          columns: defaultHiddenRiskGridColumns,
        }}
        onRowClicked={(event: RowClickedEvent) => {
          onRowClicked && onRowClicked(event);
        }}
        onColumnMoved={(event: ColumnMovedEvent) => {
          if (event.finished) {
            const updatedColumnDefs = event.api.getColumnDefs();

            dispatch(
              setGridColumns({
                gridType: GridType.None,
                columns: getAllColumnDefs(
                  noneRiskTableColumns,
                  updatedColumnDefs || []
                ),
              })
            );
          }
        }}
        onColumnVisible={(event: ColumnVisibleEvent) => {
          const updatedColumnDefs = event.api.getColumnDefs();

          dispatch(
            setGridColumns({
              gridType: GridType.None,
              columns: getAllColumnDefs(
                noneRiskTableColumns,
                updatedColumnDefs || []
              ),
            })
          );
        }}
        paginationProps={{
          pageSize: findingsFilterState.pageSize,
          currentPage: findingsFilterState.page,
          onPageChange,
          onPageSizeChange,
          totalItems: findingsWithResource?.totalItems || 0,
        }}
        isLoading={isLoading}
        onSelect={onHandleSelectionChange}
        onSort={onHandleColumnOrderChange}
        selectionModel={
          riskGridSelectionProps.selectedChildren as Array<string>
        }
        sortModel={orderParams as OrderState}
        keepCurrentSelections
        otherComponents={{
          NoDataBackdropComponent: (
            <NoDataBackdrop descriptionText="Try relaxing your search criteria" />
          ),
        }}
        loadingOverlayComponent={() => <CircularProgress />}
        getRowId={(row: GetRowIdParams) => {
          return row.data.findingId;
        }}
        gridRef={gridRef}
        isRowNavigable
      />
    </>
  );
};
