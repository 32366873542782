import { ColGroupDef } from '@ag-grid-community/core';
import { ColDef } from 'ag-grid-community';
import Status from 'Common/components/Status';
import { FindingsWithResource } from 'FindingDetails/interfaces/FindingsWithResources';
import { FunctionComponent } from 'react';
import CommonChip from 'shared/components/CommonChip';
import CommonIconCell from 'shared/components/CommonIconCell';
import CommonSeverityChip from 'shared/components/CommonSeverityChip';
import CommonSimpleDataGrid from 'shared/components/CommonSimpleDataGrid';
import TextOverflow from 'shared/components/TextOverflowComponent';
import {
  OverflowDirection,
  OverflowTextDisplay,
} from 'shared/components/TextOverflowComponent/TextOverflow';
import { RiskFindingDetailsHandler } from 'shared/handlers/risk-finding-details.handler';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

const riskFindingDetailsHandler = new RiskFindingDetailsHandler();

const chatFindingsTableColumns: Array<ColDef | ColGroupDef> = [
  {
    field: 'title',
    headerName: 'Risk Name',
    headerClass: 'column-disable-sort',
    width: 300,
    cellRenderer: (params: any) => {
      return (
        <TextOverflow
          direction={OverflowDirection.WIDTH}
          type={OverflowTextDisplay.ELLIPSIS}
          className="cell-text-renderer"
        >
          {params?.data?.title}
        </TextOverflow>
      );
    },
  },
  {
    field: 'findingSource',
    headerName: 'Event Source',
    headerClass: 'column-disable-sort',
    cellRenderer: (params: any) => {
      return (
        <CommonIconCell
          iconUrl={params?.data?.sourceAppLogoUrl}
          label={params?.data?.findingSource}
          maxWidth={140}
          data={
            params?.data?.duplicatedApplications?.length === 0
              ? []
              : riskFindingDetailsHandler.transformAppListToCommonIconsList(
                  params?.data?.duplicatedApplications,
                  'applicationId',
                  'sourceAppLogoUrl'
                )
          }
        />
      );
    },
    width: 150,
  },
  {
    field: 'businessUnitName',
    headerName: 'Service',
    headerClass: 'column-disable-sort',
    cellRenderer: (params: any) => {
      return params?.data?.businessUnitName ? (
        <CommonChip label={params?.data?.businessUnitName} />
      ) : (
        <div>-</div>
      );
    },
    width: 150,
  },
  {
    field: 'status',
    headerName: 'Status',
    headerClass: 'column-disable-sort',
    cellRenderer: (params: any) => {
      return <Status label={params.data?.status} readonly />;
    },
    width: 180,
  },
  {
    field: 'severity',
    headerName: 'Severity',
    headerClass: 'column-disable-sort',
    flex: 1,
    cellRenderer: (params: any) => {
      return <CommonSeverityChip severityNumber={params.data?.severity} />;
    },
  },
];

interface ChatFindingsGridProps extends BaseComponentProps {
  findingList?: Array<FindingsWithResource>;
}

export const ChatFindingsGrid: FunctionComponent<ChatFindingsGridProps> = ({
  findingList,
}) => {
  return (
    <div className="chat-findings-grid-container">
      <div style={{ display: 'none' }} className="chat-findings-list">
        {JSON.stringify(findingList)}
      </div>
      <CommonSimpleDataGrid
        rowData={findingList}
        columnDefs={chatFindingsTableColumns as any}
        domLayout="autoHeight"
      />
    </div>
  );
};
