import ItemWithIcon from 'Common/components/ItemWithIcon';
import { FunctionComponent } from 'react';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import OpusSvgIcon from '../IconComponents/OpusSvgIcon';
import OpusImageIcon from '../IconComponents/OpusImageIcon';
import styles from './resource-cell.module.scss';

interface ResourceCellProps extends BaseComponentProps {
  name: string;
  type?: string;
  iconUrl?: string;
  maxWidth?: number;
}

export const renderResourceIcon = (resourceCellProps: ResourceCellProps) => {
  if (resourceCellProps.iconUrl) {
    return (
      <OpusImageIcon
        className={styles.resourceIconWrapper}
        imageClassName={styles.logo}
        alt={resourceCellProps.type}
        src={resourceCellProps.iconUrl || ''}
        title={resourceCellProps.type}
      />
    );
  }
  return <OpusSvgIcon type={SVG_ICON_TYPES.BUCKET_ICON} />;
};

export const ResourceCell: FunctionComponent<ResourceCellProps> = ({
  name,
  type,
  iconUrl,
  maxWidth = 500,
}) => {
  if ((!name || name.length === 0) && (!type || type.length === 0))
    return <div>-</div>;

  return (
    <ItemWithIcon
      maxWidth={maxWidth}
      icon={renderResourceIcon({
        name,
        type,
        iconUrl,
      })}
      description={type}
      title={name?.length > 0 ? name : 'N/A'}
      missingTitleKey={''}
    />
  );
};
