import { createApi } from '@reduxjs/toolkit/query/react';
import FindingEvent from '../components/Timeline/interfaces/finding-event';
import { FindingSeverity } from '../interfaces/severity';
import { FindingImmutableStatus, FindingStatus } from '../interfaces/status';
import Resource from '../interfaces/resource';
import {
  FindingsWithResourceRequestBody,
  FindingsWithResourceResponse,
} from 'FindingDetails/interfaces/FindingsWithResources';
import {
  AvailableUser,
  AvailableUsersParams,
} from 'FindingDetails/interfaces/Users';
import axiosFetchBaseQuery from 'Common/utils/axios-base-query';
import { EnvironmentType } from '../../Settings/interfaces/EnvironmentType.enum';
import { IaCItem } from 'IaCFindings/interfaces/IaCFinding';
import commonConfig from 'Config';
import {
  FindingWidget,
  JsonWidget,
} from 'shared/models/data/finding-widget.model';
import {
  OpenTicketData,
  OpenTicketResponse,
  TicketSuggestion,
  TicketSuggestionParams,
} from '../interfaces/create-ticket';
import { ResourceOwner } from 'shared/models/data/resource-owner.model';
import { FindingContentType } from '../../shared/enums/findings.enum';
import { CodeEventItem } from 'Cloud2Code/interfaces/code-event-item.model';
import { DuplicationState } from 'Risk/interfaces/DuplicationState.enum';
import { VulnerabilityMetadata } from 'shared/models/data/vulnerability.model';
import { MultiFindingsTicketCreateDto } from 'FindingDetails/components/FindingContent/components/MultiFindingTicketModal/MultiFindingTicketModal';

interface InteractionResponse {
  stepId?: string;
  interactingStepName?: string;
  createdAt?: Date;
  options?: string[];
  title?: string;
  message?: string;
  submitButtonText?: string;
  multipleChoice: boolean;
}

export interface SummaryResponse {
  applicationId: string;
  appName: string;
  appLogo: string;
  assigneeUserId: string | null;
  assigneeUserName: string;
  assigneeProfilePicture: string;
  cloudProvider: string;
  content: object;
  externalCreatedAt: Date;
  description: string;
  findingSource: string;
  id: string;
  severity: FindingSeverity;
  riskScore: number;
  status: FindingStatus | FindingImmutableStatus;
  tenantId: string;
  title: string;
  updatedAt: Date;
  businessUnit: string;
  businessUnitEnvironment: EnvironmentType;
  cloudProviderLogo: string;
  slaBreachDate: Date;
  externalId?: string;
  category?: string;
  sensorLink?: string;
  assignee?: SummaryAssignee;
  sensor?: SummarySensor;
  hasTicket?: boolean;
  workspaceExternalId?: string;
  duplicationState: DuplicationState;
  duplicationIndicator?: string;
  vulnerabilityIds: Array<string>;
  mostSignificantVulnerabilityId?: string;
}

export interface SummaryAssignee {
  avatarUrl?: string;
  id: string;
  name: string;
}

export interface SummarySensor {
  logoUrl?: string;
  type?: string;
}

interface InteractionCreate {
  stepId: string;
  results: any;
}

export interface AutocompleteOption {
  id?: string;
  label?: string;
  keyLabel?: string;
  value: string;
  iconUrl?: string;
  configurationParameters?: any;
}

export interface FindingWorkflow {
  id: string;
  createdAt: Date;
  workflowDefName: string;
  workflowDefId: string;
  findingId: string;
  name: string;
  rootWorkflowSeqId: number;
  solutionType: string;
  familyId: string;
}

export enum FindingReducerTags {
  TicketCreate = 'TicketCreate',
  OwnerCreate = 'OwnerCreate',
  FindingException = 'FindingException',
  ActivityComment = 'ActivityComment',
}

const findingApi = createApi({
  reducerPath: 'findingApi',
  baseQuery: axiosFetchBaseQuery({ baseUrl: commonConfig.bffBaseUrl }),
  tagTypes: [
    FindingReducerTags.TicketCreate,
    FindingReducerTags.OwnerCreate,
    FindingReducerTags.FindingException,
    FindingReducerTags.ActivityComment,
  ],
  endpoints: (builder) => ({
    fetchInteraction: builder.query<InteractionResponse, string>({
      query: (findingId: string) =>
        `findings/${findingId}/content/${FindingContentType.INTERACTION}`,
      transformResponse: async (response) => {
        return {
          ...(response as InteractionResponse),
        };
      },
    }),
    fetchActionsTaken: builder.query<string[], string>({
      query: (findingId: string) =>
        `findings/${findingId}/content/${FindingContentType.ACTIONS_TAKEN}`,
      transformResponse: async (response) => {
        return response as string[];
      },
    }),
    postFindingTimelineComment: builder.mutation({
      query: ({ id, body }) => ({
        url: `findings/${id}/timeline/comments`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [FindingReducerTags.ActivityComment],
    }),
    postFindingInteraction: builder.mutation({
      query: ({ findingId, body }) => ({
        url: `findings/${findingId}/content/${FindingContentType.INTERACTION}`,
        method: 'POST',
        body,
      }),
    }),
    fetchTicketSuggestion: builder.mutation({
      query: (data: TicketSuggestionParams) => ({
        url: `findings/${data.findingId}/ticket-suggestion/${data.appId}`,
        method: 'GET',
        body: data,
      }),
      transformResponse: async (response) => {
        return response as TicketSuggestion;
      },
    }),
    openTicket: builder.mutation<any, OpenTicketData>({
      query: (data: OpenTicketData) => ({
        url: `findings/actions/${data.findingId}/${data.ticketApplication}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [FindingReducerTags.TicketCreate],
    }),
    fetchSummary: builder.query<SummaryResponse, string>({
      query: (findingId: string) =>
        `findings/${findingId}/content/${FindingContentType.SUMMARY}`,
      transformResponse: async (response) => {
        return response as SummaryResponse;
      },
      providesTags: [
        FindingReducerTags.TicketCreate,
        FindingReducerTags.FindingException,
      ],
    }),
    fetchTimeline: builder.query<FindingEvent[], string>({
      query: (findingId: string) =>
        `findings/${findingId}/content/${FindingContentType.TIMELINE}`,
      transformResponse: async (response) => {
        return response as FindingEvent[];
      },
      providesTags: [
        FindingReducerTags.TicketCreate,
        FindingReducerTags.FindingException,
        FindingReducerTags.ActivityComment,
      ],
    }),
    fetchFindingWorkflows: builder.mutation<FindingWorkflow[], string>({
      query: (findingId: string) => `findings/${findingId}/workflows`,
      transformResponse: async (response) => {
        return response as FindingWorkflow[];
      },
    }),
    findingSystemAction: builder.mutation({
      query: ({ findingId, action }) => ({
        url: `findings/${findingId}/system/${action}`,
        method: 'POST',
      }),
    }),
    mapRepos: builder.mutation({
      query: () => ({
        url: `iacs/actions/map-repos`,
        method: 'POST',
      }),
    }),
    fetchFindingWorkflowDetails: builder.mutation({
      query: ({ findingId, workflowId }) => ({
        url: `findings/${findingId}/workflow`,
        method: 'POST',
        body: {
          workflowId,
        },
      }),
    }),
    fetchFindingWorkflowStepParameters: builder.query({
      query: ({ findingId, stepDefinitionId }) =>
        `findings/${findingId}/workflow/steps/${stepDefinitionId}/parameters`,
    }),
    getFindingWorkflowStepParameters: builder.mutation({
      query: ({ findingId, stepDefinitionId }) => ({
        url: `findings/${findingId}/workflow/steps/${stepDefinitionId}/parameters`,
        method: 'GET',
      }),
    }),
    fetchFindingsWithResource: builder.mutation({
      query: (params: FindingsWithResourceRequestBody) => {
        let { headers, ...restParams } = params;
        let request: any = {
          method: 'POST',
          url: `findings/search`,
          body: { ...restParams },
        };
        if (headers) request.headers = { accept: headers };
        return request;
      },
      transformResponse: async (response) => {
        return response as FindingsWithResourceResponse;
      },
    }),
    bumpFindings: builder.mutation({
      query: ({ days, params }) => {
        return {
          method: 'POST',
          url: `findings/bump/${days}`,
          body: params,
        };
      },
      transformResponse: async (response) => {
        return response as FindingsWithResourceResponse;
      },
    }),
    fetchResources: builder.query<Resource[], string>({
      query: (findingId) => ({
        url: `findings/${findingId}/content/${FindingContentType.RESOURCES}`,
        method: 'GET',
      }),
      transformResponse: async (response) => {
        // await delay(1500); // dev; simulating latency
        return response as Resource[]; // prod
      },
    }),
    fetchAvailableUsersToAssign: builder.mutation<
      AvailableUser[],
      Partial<AvailableUsersParams>
    >({
      query: (params) => ({
        url: 'users/search',
        method: 'POST',
        body: params,
      }),
      transformResponse: async (response) => {
        return response as AvailableUser[];
      },
    }),
    fetchFindingsConnectionByFilters: builder.mutation({
      query: (filters) => ({
        method: 'POST',
        url: `findings/connections/search`,
        body: filters,
      }),
    }),
    fetchIacDetailsByFindingId: builder.query<IaCItem[], string>({
      query: (findingId) => ({
        url: `findings/${findingId}/content/${FindingContentType.IAC}`,
        method: 'GET',
      }),
      transformResponse: async (response) => {
        return response as IaCItem[];
      },
    }),
    updateFindingAssignee: builder.mutation({
      query: ({ findingId, body }) => ({
        url: `findings/${findingId}/assignee`,
        method: 'PUT',
        body,
      }),
    }),
    updateFindingStatus: builder.mutation({
      query: ({ findingId, body }) => ({
        url: `findings/${findingId}/status`,
        method: 'PUT',
        body,
      }),
    }),
    fetchOwnershipWidget: builder.query<FindingWidget, string>({
      query: (findingId) => ({
        url: `widgets/findings/${findingId}/ownership`,
        method: 'GET',
      }),
    }),
    fetchAffectedPackages: builder.query<object[], string>({
      query: (findingId) => ({
        url: `findings/${findingId}/content/${FindingContentType.AFFECTED_PACKAGES}`,
        method: 'GET',
      }),
    }),
    fetchEnvironmentWidget: builder.query<FindingWidget, string>({
      query: (findingId) => ({
        url: `widgets/findings/${findingId}/environment`,
        method: 'GET',
      }),
    }),
    fetchJsonRawDataWidget: builder.query<JsonWidget, string>({
      query: (findingId) => ({
        url: `widgets/findings/${findingId}/jsonRawData`,
        method: 'GET',
      }),
    }),
    createResourceOwner: builder.mutation({
      query: (createBody: any) => {
        return {
          url: `resources/${createBody.resourceId}/owners`,
          method: 'POST',
          body: createBody,
        };
      },
      invalidatesTags: [FindingReducerTags.OwnerCreate],
    }),
    searchResourceOwners: builder.mutation<Array<ResourceOwner>, any>({
      query: (searchBody: any) => ({
        url: `resources/${searchBody.resourceId}/owners/search`,
        method: 'POST',
      }),
    }),
    fetchResourceOwners: builder.query<Array<ResourceOwner>, any>({
      query: (searchBody: any) => ({
        url: `resources/${searchBody.resourceId}/owners/search`,
        method: 'POST',
      }),
      providesTags: [FindingReducerTags.OwnerCreate],
    }),
    setPrimaryResourceOwner: builder.mutation({
      query: (updateBody: any) => ({
        url: `resources/${updateBody.resourceId}/owners/${updateBody.ownerId}/actions/primary`,
        method: 'POST',
      }),
      invalidatesTags: [FindingReducerTags.OwnerCreate],
    }),
    deleteResourceOwner: builder.mutation({
      query: (updateBody: any) => ({
        url: `resources/${updateBody.resourceId}/owners/${updateBody.ownerId}`,
        method: 'DELETE',
      }),
    }),
    getFindingExceptionsByFinding: builder.query({
      query: (findingId: string) => ({
        url: `findings/${findingId}/exceptions`,
        method: 'GET',
      }),
      providesTags: [FindingReducerTags.FindingException],
    }),
    getLatestFindingExceptionByFinding: builder.query({
      query: (findingId: string) => ({
        url: `findings/${findingId}/exceptions/latest`,
        method: 'GET',
      }),
      providesTags: [FindingReducerTags.FindingException],
    }),
    createFindingException: builder.mutation({
      query: (body) => ({
        url: `findings/${body.findingId}/exceptions`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [FindingReducerTags.FindingException],
    }),
    approveFindingException: builder.mutation({
      query: (body) => ({
        url: `findings/${body.findingId}/exceptions/${body.exceptionId}/actions/approve`,
        method: 'POST',
      }),
      invalidatesTags: [FindingReducerTags.FindingException],
    }),
    declineFindingException: builder.mutation({
      query: (body) => ({
        url: `findings/${body.findingId}/exceptions/${body.exceptionId}/actions/decline`,
        method: 'POST',
        body: {
          message: body.message,
        },
      }),
      invalidatesTags: [FindingReducerTags.FindingException],
    }),
    revokeFindingException: builder.mutation({
      query: (body) => ({
        url: `findings/${body.findingId}/exceptions/${body.exceptionId}/actions/revoke`,
        method: 'POST',
      }),
      invalidatesTags: [FindingReducerTags.FindingException],
    }),
    cancelFindingException: builder.mutation({
      query: (body) => ({
        url: `findings/${body.findingId}/exceptions/${body.exceptionId}/actions/cancel`,
        method: 'POST',
      }),
      invalidatesTags: [FindingReducerTags.FindingException],
    }),
    unlinkFindingItemRelation: builder.mutation({
      query: (id: string) => ({
        url: `findings/actions/${id}/unlink`,
        method: 'POST',
      }),
      invalidatesTags: [
        FindingReducerTags.FindingException,
        FindingReducerTags.TicketCreate,
      ],
    }),
    getFindingOperationalStatus: builder.query({
      query: (findingId: string) => ({
        url: `findings/${findingId}/content/${FindingContentType.OPERATIONAL_STATUS}`,
        method: 'GET',
      }),
      providesTags: [
        FindingReducerTags.TicketCreate,
        FindingReducerTags.ActivityComment,
      ],
    }),
    getFindingRiskScoreBreakdown: builder.query({
      query: (findingId: string) => ({
        url: `findings/${findingId}/content/${FindingContentType.RISK_SCORE_BREAKDOWN}`,
        method: 'GET',
      }),
    }),
    getFindingContentByType: builder.mutation({
      query: (body) => ({
        url: `findings/${body.findingId}/content/${body.contentType}`,
        method: 'GET',
      }),
    }),
    getFindingEvidenceContent: builder.query({
      query: (findingId: string) => ({
        url: `findings/${findingId}/content/${FindingContentType.EVIDENCE}`,
        method: 'GET',
      }),
    }),
    getFindingSourceContent: builder.query({
      query: (findingId: string) => ({
        url: `findings/${findingId}/content/${FindingContentType.SOURCE_INFORMATION}`,
        method: 'GET',
      }),
    }),
    getFindingWidgets: builder.query({
      query: (findingId: string) => ({
        url: `widgets/findings/${findingId}`,
        method: 'GET',
      }),
    }),
    fetchFindingSummary: builder.mutation<SummaryResponse, string>({
      query: (findingId: string) =>
        `findings/${findingId}/content/${FindingContentType.SUMMARY}`,
      transformResponse: async (response) => {
        return response as SummaryResponse;
      },
      invalidatesTags: [
        FindingReducerTags.TicketCreate,
        FindingReducerTags.FindingException,
      ],
    }),
    fetchVulnerabilityMetadataById: builder.query<
      VulnerabilityMetadata,
      string
    >({
      query: (id: string) => `vulnerabilities/metadata/${id}`,
    }),
    createMultiFindingTicket: builder.mutation({
      query: (body: MultiFindingsTicketCreateDto) => ({
        url: `findings/actions/create-ticket/multi-finding`,
        method: 'POST',
        body,
      }),
    }),
    createChatDialog: builder.mutation({
      query: ({ message }) => ({
        url: `findings/dialog`,
        method: 'POST',
      }),
    }),
    postChatMessage: builder.mutation({
      query: ({ message }) => ({
        url: `findings/chat`,
        method: 'POST',
        body: {
          message,
        },
      }),
    }),
  }),
});

export default findingApi;

export const {
  useFetchInteractionQuery,
  useFetchActionsTakenQuery,
  useFetchSummaryQuery,
  useFetchTimelineQuery,
  useFetchFindingsWithResourceMutation,
  useFetchResourcesQuery,
  useFetchAvailableUsersToAssignMutation,
  useBumpFindingsMutation,
  useFetchIacDetailsByFindingIdQuery,
  useFetchFindingWorkflowDetailsMutation,
  useFetchFindingWorkflowStepParametersQuery,
  useGetFindingWorkflowStepParametersMutation,
  useUpdateFindingAssigneeMutation,
  useUpdateFindingStatusMutation,
  usePostFindingTimelineCommentMutation,
  usePostFindingInteractionMutation,
  useFetchFindingWorkflowsMutation,
  useFindingSystemActionMutation,
  useMapReposMutation,
  useFetchOwnershipWidgetQuery,
  useFetchAffectedPackagesQuery,
  useFetchEnvironmentWidgetQuery,
  useFetchJsonRawDataWidgetQuery,
  useLazyFetchIacDetailsByFindingIdQuery,
  useOpenTicketMutation,
  useFetchTicketSuggestionMutation,
  useCreateResourceOwnerMutation,
  useSearchResourceOwnersMutation,
  useFetchResourceOwnersQuery,
  useSetPrimaryResourceOwnerMutation,
  useDeleteResourceOwnerMutation,
  useCreateFindingExceptionMutation,
  useGetFindingExceptionsByFindingQuery,
  useApproveFindingExceptionMutation,
  useDeclineFindingExceptionMutation,
  useRevokeFindingExceptionMutation,
  useCancelFindingExceptionMutation,
  useUnlinkFindingItemRelationMutation,
  useGetFindingEvidenceContentQuery,
  useGetFindingSourceContentQuery,
  useGetFindingOperationalStatusQuery,
  useGetFindingWidgetsQuery,
  useGetFindingRiskScoreBreakdownQuery,
  useGetLatestFindingExceptionByFindingQuery,
  useFetchFindingSummaryMutation,
  useFetchFindingsConnectionByFiltersMutation,
  useFetchVulnerabilityMetadataByIdQuery,
  useCreateMultiFindingTicketMutation,
  usePostChatMessageMutation,
  useCreateChatDialogMutation,
} = findingApi;
