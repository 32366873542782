import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress, Skeleton, Switch } from '@mui/material';
import { isEqualWith } from 'lodash';
import { AutocompleteOption } from 'FindingDetails/store/api';
import {
  useCreateNotificationRuleMutation,
  useFetchNotificationRuleQuery,
  useUpdateNotificationRuleMutation,
} from 'Settings/store/api';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AccordionSection } from 'shared/components/AccordionSection/AccordionSection';
import { CommonModalContainer } from 'shared/components/CommonModalContainer';
import { ContentSection } from 'shared/components/ContentSection/ContentSection';
import { OpusSvgIcon } from 'shared/components/IconComponents/OpusSvgIcon/OpusSvgIcon';
import { InputLabelWrapper } from 'shared/components/InputLabelWrapper/InputLabelWrapper';
import { SelectChipGroup } from 'shared/components/SelectChipGroup';
import {
  SelectChipCategory,
  SelectChipChangePayload,
} from 'shared/components/SelectChipGroup/SelectChipGroup';
import {
  notificationRuleStatusItems,
  notificationRuleFormRequiredFields,
  notificationRuleSchema,
  notificationRuleExceptionTriggerOptions,
  notificationRuleSLATriggerOptions,
  notificationRuleTicketTriggerOptions,
  notificationRuleConditionOptions,
  SlaAgeTypeAutoComplete,
  SlaAgeType,
} from 'shared/fixtures/data/notification-rule.data';
import {
  NotificationRuleAdditionalFormData,
  NotificationRuleDataHandler,
  NotificationRuleDefaultFormData,
} from 'shared/handlers/notification-rule-data.handler';
import { useFetchNotificationSettingsOptionItems } from 'shared/hooks/useFetchNotificationSettingsOptionItems';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import {
  NotificationRule,
  NotificationRuleTrigger,
  TeamRoleOptionsList,
  UserRoleOptionsList,
} from 'shared/models/data/notification-rule.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import NotificationRuleRoleAutocomplete from 'Settings/components/NotificationRuleRoleAutocomplete';
import { usePermissions } from 'shared/hooks/usePermissionActions';
import { ApplicationPermission } from 'shared/enums/permission.enum';
import FilterChipGroup from 'shared/components/DataFilters/FilterChipGroup';
import {
  CategoryState,
  MultiSelectState,
} from 'shared/models/data/data-filter.model';
import { useGetLabelsMutation } from 'shared/store/api';
import Autocomplete from 'Common/components/Autocomplete';
import BulkActionDropdown from 'FindingDetails/components/FindingContent/components/BulkActionDropdown';
import {
  SystemLabel,
  SystemLabelType,
} from 'shared/components/LabelManagementDropdown/LabelManagementDropdown';
import CommonTooltip from 'Common/components/CommonTooltip/CommonTooltip';
import {
  NotificationRuleFrequency,
  NotificationRuleFrequencyList,
} from 'Settings/interfaces/NotificationRuleFrequency.enum';
import { DateService } from 'shared/services/date.service';

interface NotificationRuleDrawerPanelProps extends BaseComponentProps {
  onCancel: () => void;
  detectFormChanged?: (param: boolean) => void;
  cancelationModalProps?: {
    open: boolean;
    onClose: () => void;
    onOpen: () => void;
  };
}

const notificationRuleDataHandler = new NotificationRuleDataHandler();
const dateService = new DateService();

export const NotificationRuleDrawerPanel: FunctionComponent<
  NotificationRuleDrawerPanelProps
> = ({ onCancel, detectFormChanged, cancelationModalProps }) => {
  const [searchParams] = useQueryParams();
  const [selectedTime, setSelectedTime] = useState<
    AutocompleteOption | undefined
  >(undefined);

  const permissionActions = usePermissions();

  const { t: translation } = useTranslation();

  const { teamOptionList, emailOptionList, flowOptionList } =
    useFetchNotificationSettingsOptionItems();

  const [selectedLabels, setSelectedLabels] = useState<
    Array<AutocompleteOption>
  >([]);

  const [selectedFlows, setSelectedFlows] = useState<Array<AutocompleteOption>>(
    []
  );
  const [isGroupNotificationEnabled, setIsGroupNotificationEnabled] =
    useState<boolean>(false);

  const [labelAnchorEl, setLabelAnchorEl] = useState<HTMLDivElement | null>(
    null
  );

  const [getLabelItems, { data: labelItems, isLoading: labelItemsLoading }] =
    useGetLabelsMutation();

  const [selectedEmailList, setSelectedEmailList] = useState<
    Array<AutocompleteOption>
  >([]);
  const [selectedEmailRolesList, setSelectedEmailRolesList] = useState<
    Array<AutocompleteOption>
  >([]);

  const [selectedTeamRolesList, setSelectedTeamRolesList] = useState<
    Array<AutocompleteOption>
  >([]);

  const [selectedInterval, setSelectedInterval] = useState<
    AutocompleteOption | undefined
  >(undefined);
  const [selectedTeamList, setSelectedTeamList] = useState<
    Array<AutocompleteOption>
  >([]);

  const [environmentTypeMismatchError, setEnvironmentTypeMismatchError] =
    useState<boolean>(false);

  const [additionalFormData, setAdditionalFormData] =
    useState<NotificationRuleAdditionalFormData>({
      emails: [],
      teams: [],
      emailRoles: [],
      teamRoles: [],
      properties: {},
      resourceOwner: false,
      openTicket: false,
      closedTicket: false,
      triggers: [],
      labels: [],
      flows: [],
      suppressFindings: false,
    });

  const hasExceptionsReadPermission = permissionActions.includes(
    ApplicationPermission.EXCEPTIONS_READ
  );

  const updatedNotificationRuleStatusItems = useMemo<
    Array<SelectChipCategory>
  >(() => {
    if (hasExceptionsReadPermission) {
      return [
        {
          ...notificationRuleStatusItems[0],
          optionList: [
            ...notificationRuleStatusItems[0].optionList,
            ...notificationRuleExceptionTriggerOptions,
            ...notificationRuleTicketTriggerOptions,
            ...notificationRuleSLATriggerOptions,
          ],
        },
      ];
    }
    return notificationRuleStatusItems;
  }, []);

  const { data: notificationRule, isFetching: notificationRuleLoading } =
    useFetchNotificationRuleQuery(searchParams?.ruleId as string, {
      skip: !searchParams?.ruleId,
      refetchOnMountOrArgChange: true,
    });

  const defaultFormValues = useMemo(() => {
    if (notificationRule) {
      return notificationRuleDataHandler.transformNotificationRuleDataToFormData(
        notificationRule
      );
    }

    return undefined;
  }, [notificationRule, updatedNotificationRuleStatusItems]);

  const [
    createNotificationRule,
    {
      isLoading: createNotificationRuleLoading,
      isSuccess: createNotificationRuleSuccess,
    },
  ] = useCreateNotificationRuleMutation();

  const [
    updateNotificationRule,
    {
      isLoading: updateNotificationRuleLoading,
      isSuccess: updateNotificationRuleSuccess,
    },
  ] = useUpdateNotificationRuleMutation();

  useEffect(() => {
    getLabelItems({
      queryString: 'orderBy=label&orderType=ASC&skip=0&take=10000',
    });
  }, []);

  useEffect(() => {
    if (createNotificationRuleSuccess || updateNotificationRuleSuccess) {
      onCancel && onCancel();
    }
  }, [createNotificationRuleSuccess, updateNotificationRuleSuccess]);

  useEffect(() => {
    if (defaultFormValues) {
      setAdditionalFormData({
        ...defaultFormValues,
      });

      setSelectedEmailList(defaultFormValues.emails);
      setSelectedTeamList(defaultFormValues.teams);
      setSelectedEmailRolesList(defaultFormValues.emailRoles);
      setSelectedTeamRolesList(defaultFormValues.teamRoles);
      setSelectedLabels(defaultFormValues.labels);
      setSelectedFlows(defaultFormValues.flows);
      setSelectedInterval(defaultFormValues.triggerConfigs?.grouped?.interval);
      setSelectedTime(defaultFormValues.triggerConfigs?.grouped?.repeatAt);
      setFormValue(
        'slaAge',
        defaultFormValues?.triggerConfigs?.[
          NotificationRuleTrigger.FINDING_SLA_BREACHED
        ]?.slaAge || 0
      );

      setIsGroupNotificationEnabled(
        !!defaultFormValues?.triggerConfigs?.grouped
      );

      setFormValue(
        'slaAgeType',
        defaultFormValues?.triggerConfigs?.[
          NotificationRuleTrigger.FINDING_SLA_BREACHED
        ]?.slaAgeType || SlaAgeType.DAYS
      );
      if (defaultFormValues.project) {
        setFormValue('project', defaultFormValues.project);
      }

      for (const formValueKey in defaultFormValues) {
        if (
          formValueKey === 'advanceFilterItems' ||
          formValueKey === 'triggers' ||
          formValueKey === 'emails'
        ) {
          return;
        } else {
          setFormValue(
            formValueKey as any,
            defaultFormValues[
              formValueKey as keyof NotificationRuleDefaultFormData
            ]
          );
        }
      }
    }
  }, [defaultFormValues]);

  const {
    register,
    formState: { errors },
    setValue: setFormValue,
    setError,
    getValues,
    watch,
    clearErrors,
  } = useForm({
    resolver: yupResolver(notificationRuleSchema),
  });

  const validateFormBeforeSubmit = () => {
    const formValues = getValues();

    let isValid = true;

    const isPropertyChecked =
      Object.keys(additionalFormData.properties).length > 0;

    if (isPropertyChecked) {
      if (environmentTypeMismatchError) {
        setError('properties', {
          message: 'BU not associated with chosen environment',
        });
        isValid = false;
      } else {
        clearErrors('properties');
      }
    } else {
      setError('properties', { message: 'Properties are required' });
      isValid = false;
    }

    if (additionalFormData.triggers.length === 0) {
      setError('triggers', { message: 'Triggers are required' });
      isValid = false;
    } else {
      clearErrors('triggers');
    }

    for (const requiredField of notificationRuleFormRequiredFields) {
      if (formValues[requiredField]?.length === 0) {
        setError(requiredField as any, {
          message: `${requiredField} is required`,
        });
        isValid = false;
      } else {
        clearErrors(requiredField as any);
      }
    }

    return isValid;
  };

  const isTicketingConfigured = useMemo(() => {
    return (
      defaultFormValues?.openTicket ||
      defaultFormValues?.closedTicket ||
      !!defaultFormValues?.project
    );
  }, [defaultFormValues]);

  const isCommunicationConfigured = useMemo(() => {
    const hasEmails =
      !!defaultFormValues?.emails?.length || !!defaultFormValues?.teams?.length;
    const hasRoles =
      !!defaultFormValues?.emailRoles?.length ||
      !!defaultFormValues?.teamRoles?.length;
    return hasEmails || hasRoles;
  }, [defaultFormValues]);

  const handleTeamRoleChange = (
    model: string,
    valueList: AutocompleteOption[] | AutocompleteOption
  ) => {
    setSelectedTeamRolesList(valueList as Array<AutocompleteOption>);
    setAdditionalFormData((prevAdditionalFormData) => {
      return {
        ...prevAdditionalFormData,
        teamRoles: valueList as Array<AutocompleteOption>,
      };
    });
  };

  const handleTeamChange = (
    model: string,
    valueList: AutocompleteOption[] | AutocompleteOption
  ) => {
    setSelectedTeamList(valueList as Array<AutocompleteOption>);
    setAdditionalFormData((prevAdditionalFormData) => {
      return {
        ...prevAdditionalFormData,
        teams: valueList as Array<AutocompleteOption>,
      };
    });
  };

  const handleEmailChange = (
    model: string,
    valueList: AutocompleteOption[] | AutocompleteOption
  ) => {
    setSelectedEmailList(valueList as Array<AutocompleteOption>);
    setAdditionalFormData((prevAdditionalFormData) => {
      return {
        ...prevAdditionalFormData,
        emails: valueList as Array<AutocompleteOption>,
      };
    });
  };

  const handleEmailRoleChange = (
    model: string,
    valueList: AutocompleteOption[] | AutocompleteOption
  ) => {
    setSelectedEmailRolesList(valueList as Array<AutocompleteOption>);
    setAdditionalFormData((prevAdditionalFormData) => {
      return {
        ...prevAdditionalFormData,
        emailRoles: valueList as Array<AutocompleteOption>,
      };
    });
  };

  const handleLabelsChange = (valueList: AutocompleteOption[]) => {
    setSelectedLabels(valueList);
    setAdditionalFormData((prevAdditionalFormData) => {
      return {
        ...prevAdditionalFormData,
        labels: valueList,
      };
    });
  };

  const handleFlowsChange = (value: AutocompleteOption) => {
    setSelectedFlows([value]);
    setAdditionalFormData((prevAdditionalFormData) => ({
      ...prevAdditionalFormData,
      flows: [value],
    }));
  };

  const currentActiveFlow = useMemo<AutocompleteOption | undefined>(() => {
    if (selectedFlows.length > 0 && flowOptionList.options.length > 0) {
      if (selectedFlows[0].label) {
        return selectedFlows[0];
      }

      return flowOptionList.options.find(
        (flowOption) => flowOption.value === selectedFlows[0].value
      );
    }

    return undefined;
  }, [selectedFlows, flowOptionList.options]);

  const currentSlaAgeType = useMemo<AutocompleteOption>(() => {
    const slaAgeType =
      additionalFormData.triggerConfigs?.[
        NotificationRuleTrigger.FINDING_SLA_BREACHED
      ]?.slaAgeType;

    return (
      SlaAgeTypeAutoComplete.find((opt) => opt.value === slaAgeType) ||
      SlaAgeTypeAutoComplete[0]
    );
  }, [
    additionalFormData.triggerConfigs?.[
      NotificationRuleTrigger.FINDING_SLA_BREACHED
    ]?.slaAgeType,
  ]);
  const currentInterval = useMemo<AutocompleteOption | undefined>(() => {
    return additionalFormData?.triggerConfigs?.grouped?.interval || undefined;
  }, [additionalFormData?.triggerConfigs?.grouped?.interval]);
  const currentTime = useMemo<AutocompleteOption | undefined>(() => {
    return additionalFormData?.triggerConfigs?.grouped?.repeatAt || undefined;
  }, [additionalFormData?.triggerConfigs?.grouped?.repeatAt]);

  const renderFlowAutocomplete = () => {
    if (flowOptionList.isLoading) return <Skeleton height={70} />;

    return (
      <Autocomplete
        single
        model="flows"
        optionList={flowOptionList.options}
        loading={flowOptionList.isLoading}
        onChangeCallBack={(model, value) => {
          const workflow = value as AutocompleteOption;
          handleFlowsChange(workflow);
        }}
        areOptionsLoaded
        classes={{
          root: 'multi-select-field-1',
          paper: 'multi-select-field-paper-1',
        }}
        enableCheckbox
        placeholder={translation(`flows.details.pleaseSelectWorkflow`)}
        limitTags={4}
        values={currentActiveFlow}
        initialSelectedValues={currentActiveFlow}
        onClear={() => {
          setSelectedFlows([]);
          setAdditionalFormData((prevAdditionalFormData) => ({
            ...prevAdditionalFormData,
            flows: [],
          }));
        }}
      />
    );
  };

  const renderGroupedNotificationSection = () => {
    return (
      <div className="group-notification-container">
        <div className="group-notification-message-container">
          <div className="group-notification-label">
            <p className="label-5">
              {translation(`settings.details.groupNotificationTitle`)}
            </p>
            <CommonTooltip
              placement="top"
              title={
                <span className="opus-body-text-1 tooltip-content">
                  {translation(`settings.details.groupNotificationTooltip`)}
                </span>
              }
            >
              <span>
                <OpusSvgIcon type={SVG_ICON_TYPES.INFO_CIRCLE_ICON} />
              </span>
            </CommonTooltip>
          </div>
          <div>
            <Switch
              classes={{
                root: 'opus-switch-field-1',
              }}
              defaultChecked={isGroupNotificationEnabled}
              checked={isGroupNotificationEnabled}
              onClick={() => {
                setIsGroupNotificationEnabled((prevValue) => !prevValue);
                setAdditionalFormData((prevAdditionalFormData) => {
                  if (!isGroupNotificationEnabled) {
                    return {
                      ...prevAdditionalFormData,
                      triggerConfigs: {
                        ...prevAdditionalFormData.triggerConfigs,
                        grouped: { interval: NotificationRuleFrequencyList[0] },
                      },
                    };
                  }
                  if (prevAdditionalFormData.triggerConfigs?.grouped) {
                    const { grouped, ...remainingTriggerConfigs } =
                      prevAdditionalFormData.triggerConfigs;

                    return {
                      ...prevAdditionalFormData,
                      triggerConfigs: remainingTriggerConfigs,
                    };
                  }

                  return prevAdditionalFormData;
                });
              }}
            />
          </div>
        </div>
        {isGroupNotificationEnabled && (
          <div className="group-notification-content">
            <p className="body-1">
              {translation(`settings.details.groupNotificationDescription`)}
            </p>
            <div className="notification-group-form">
              <Autocomplete
                single
                model="interval"
                optionList={NotificationRuleFrequencyList}
                loading={false}
                onChangeCallBack={(model, value) => {
                  const selectedOption = value as AutocompleteOption;
                  setSelectedInterval(selectedOption);
                  setSelectedTime(undefined);

                  setAdditionalFormData((prevAdditionalFormData) => ({
                    ...prevAdditionalFormData,
                    triggerConfigs: {
                      ...prevAdditionalFormData.triggerConfigs,
                      grouped: {
                        interval: selectedOption,
                        repeatAt: undefined,
                      },
                    },
                  }));
                }}
                {...register('interval')}
                areOptionsLoaded
                classes={{
                  root: 'multi-select-field-1',
                  paper: 'multi-select-field-paper-1',
                }}
                enableCheckbox
                values={currentInterval}
                initialSelectedValues={currentInterval}
                onClear={() => {
                  setSelectedInterval(undefined);
                  setAdditionalFormData((prevAdditionalFormData) => {
                    const updatedTriggerConfigs = {
                      ...prevAdditionalFormData.triggerConfigs,
                      grouped: undefined,
                    };

                    return {
                      ...prevAdditionalFormData,
                      triggerConfigs: updatedTriggerConfigs,
                    };
                  });
                }}
              />

              {selectedInterval?.value === NotificationRuleFrequency.CUSTOM && (
                <Autocomplete
                  single
                  model="timePicker"
                  optionList={dateService.generateTimeOptions()}
                  loading={false}
                  values={currentTime}
                  initialSelectedValues={currentTime}
                  onChangeCallBack={(model, value) => {
                    const selectedOption = value as AutocompleteOption;
                    setSelectedTime(selectedOption);
                    setAdditionalFormData((prevAdditionalFormData) => {
                      const updatedTriggerConfigs = {
                        ...prevAdditionalFormData.triggerConfigs,
                        grouped: {
                          ...prevAdditionalFormData.triggerConfigs?.grouped,
                          repeatAt: selectedOption,
                        },
                      };

                      return {
                        ...prevAdditionalFormData,
                        triggerConfigs: updatedTriggerConfigs,
                      };
                    });
                  }}
                  areOptionsLoaded
                  classes={{
                    root: 'multi-select-field-1',
                    paper: 'multi-select-field-paper-1',
                  }}
                  enableCheckbox
                  onClear={() => {
                    setSelectedTime(undefined);
                    setAdditionalFormData((prevAdditionalFormData) => {
                      const updatedTriggerConfigs = {
                        ...prevAdditionalFormData.triggerConfigs,
                        grouped: {
                          interval:
                            prevAdditionalFormData.triggerConfigs?.grouped
                              ?.interval,
                          repeatAt: undefined,
                        },
                      };

                      return {
                        ...prevAdditionalFormData,
                        triggerConfigs: updatedTriggerConfigs,
                      };
                    });
                  }}
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  const hasFormChanged = () => {
    const formValues = getValues();
    const mergedFormData = { ...additionalFormData, ...formValues };

    if (notificationRule) {
      return !isEqualWith(
        defaultFormValues,
        mergedFormData,
        (value1: string | undefined, value2: string | undefined) => {
          if (
            (value1 === '' && value2 === undefined) ||
            (value1 === undefined && value2 === '')
          ) {
            return true;
          }
        }
      );
    } else {
      return Object.values(mergedFormData).some((value) => {
        if (Array.isArray(value)) {
          return value.length > 0;
        }
        if (typeof value === 'object' && value !== null) {
          return Object.keys(value).length > 0;
        }
        return Boolean(value);
      });
    }
  };

  useEffect(() => {
    const detectFormChanges = hasFormChanged();
    detectFormChanged && detectFormChanged(detectFormChanges);
  }, [additionalFormData, watch()]);

  const displaySlaBrachedConfig = useMemo(() => {
    const hasSlaTrigger = additionalFormData.triggers?.some(
      (trigger) =>
        trigger.value === NotificationRuleTrigger.FINDING_SLA_BREACHED
    );

    if (hasSlaTrigger) {
      return (
        <InputLabelWrapper
          label="Define SLA Breach Age"
          components={{
            EndIcon: (
              <CommonTooltip
                title={
                  <span className="campaign-live-tooltip-text">
                    {translation(
                      `settings.details.notificationRuleSlaBreachMessage`
                    )}
                  </span>
                }
                placement="top"
              >
                <span>
                  <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_INFO_ICON} />
                </span>
              </CommonTooltip>
            ),
          }}
        >
          <div className="sla-brached-config">
            <input
              type="number"
              className={`text-field-1 ${
                errors.name ? 'input-validation-error-1' : ''
              }`}
              {...register('slaAge')}
              onChange={(payload) => {
                const newSlaAge = parseInt(payload.target.value, 10) || 0;
                setFormValue('slaAge', newSlaAge);
                setAdditionalFormData((prevAdditionalFormData: any) => {
                  const updatedFormData = {
                    ...prevAdditionalFormData,
                    triggerConfigs: {
                      ...prevAdditionalFormData.triggerConfigs,
                      [NotificationRuleTrigger.FINDING_SLA_BREACHED]: {
                        ...prevAdditionalFormData.triggerConfigs?.[
                          NotificationRuleTrigger.FINDING_SLA_BREACHED
                        ],
                        slaAge: newSlaAge,
                      },
                    },
                  };

                  return updatedFormData;
                });
              }}
            ></input>

            <Autocomplete
              single
              model="slaAgeType"
              optionList={SlaAgeTypeAutoComplete}
              loading={false}
              onChangeCallBack={(model, value) => {
                const selectedOption = value as AutocompleteOption;
                setFormValue('slaAgeType', selectedOption.value);
                setAdditionalFormData((prevAdditionalFormData) => ({
                  ...prevAdditionalFormData,
                  triggerConfigs: {
                    ...prevAdditionalFormData.triggerConfigs,
                    [NotificationRuleTrigger.FINDING_SLA_BREACHED]: {
                      slaAge: prevAdditionalFormData.triggerConfigs
                        ? prevAdditionalFormData?.triggerConfigs[
                            NotificationRuleTrigger.FINDING_SLA_BREACHED
                          ]?.slaAge || 0
                        : 0,
                      slaAgeType: selectedOption.value as SlaAgeType,
                    },
                  },
                }));
              }}
              values={currentSlaAgeType}
              {...register('slaAgeType')}
              areOptionsLoaded
              classes={{
                root: 'multi-select-field-1',
                paper: 'multi-select-field-paper-1',
              }}
              initialSelectedValues={currentSlaAgeType}
            />
          </div>
        </InputLabelWrapper>
      );
    } else {
      setFormValue('slaAge', 0);
      setFormValue('slaAgeType', SlaAgeType.DAYS);
      setAdditionalFormData((prevAdditionalFormData) => ({
        ...prevAdditionalFormData,
        triggerConfigs: {
          ...prevAdditionalFormData.triggerConfigs,
          [NotificationRuleTrigger.FINDING_SLA_BREACHED]: {
            slaAge: 0,
            slaAgeType: SlaAgeType.DAYS,
          },
        },
      }));
      return <></>;
    }
  }, [additionalFormData.triggers]);

  return notificationRuleLoading ? (
    <div className="notification-rule-view-panel-loading">
      <CircularProgress size={35} />
    </div>
  ) : (
    <div className="notification-rule-view-panel">
      <CommonModalContainer
        handleClose={() => {
          cancelationModalProps?.onClose && cancelationModalProps?.onClose();
        }}
        isOpen={hasFormChanged() ? Boolean(cancelationModalProps?.open) : false}
        title={`Cancel Automation Rule ${
          notificationRule ? 'Update' : 'Creation'
        }`}
      >
        <div className="business-unit-form-modal-body">
          <div className="business-unit-form-modal-description">
            <span>Are you sure you want to cancel this action?</span>
            <span>All data will be lost</span>
          </div>
          <div className="business-unit-form-modal-buttons">
            <Button
              className="base-opus-text-button"
              onClick={() => {
                cancelationModalProps?.onClose &&
                  cancelationModalProps?.onClose();
              }}
            >
              Stay
            </Button>
            <Button
              className="base-opus-text-button"
              onClick={() => {
                cancelationModalProps?.onClose &&
                  cancelationModalProps?.onClose();
                onCancel();
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </CommonModalContainer>
      <div className="notification-rule-view-panel-header">
        <div className="notification-rule-view-panel-header-icon">
          <OpusSvgIcon type={SVG_ICON_TYPES.LIST_CHECK_ICON} />
        </div>
        <div className="notification-rule-view-panel-header-text">
          <div className="notification-rule-view-panel-header-title">
            Automation Rule {notificationRule ? 'Update' : 'Creation'}
          </div>
          <div className="notification-rule-view-panel-header-description">
            Group your organization's resources to align with service goals
          </div>
        </div>
      </div>

      <div className="notification-rule-view-panel-body">
        <form className="notification-rule-view-panel-form">
          <ContentSection
            title=""
            rootClassName="notification-rule-content-section notification-rule-base-content-section"
          >
            <InputLabelWrapper label="Name">
              <input
                type="text"
                className={`text-field-1 ${
                  errors.name ? 'input-validation-error-1' : ''
                }`}
                {...register('name')}
              ></input>
            </InputLabelWrapper>
            <InputLabelWrapper label="Description">
              <input
                type="text"
                className={`text-field-1`}
                {...register('description')}
              ></input>
            </InputLabelWrapper>
            <InputLabelWrapper label="Trigger">
              <SelectChipGroup
                rootClassName={
                  errors.triggers ? 'input-validation-error-1' : ''
                }
                categories={updatedNotificationRuleStatusItems}
                labels={{
                  addLabel: 'When',
                }}
                displayMode="single"
                onChange={(payload: SelectChipChangePayload) => {
                  setAdditionalFormData((prevAdditionalFormData) => {
                    const hasFindingOpen = payload['status']?.some(
                      (valueItem) =>
                        valueItem.value ===
                        NotificationRuleTrigger.FINDING_CREATED
                    );
                    const hasFindingClosedOrSurpressed = payload[
                      'status'
                    ]?.some(
                      (valueItem) =>
                        valueItem.value ===
                          NotificationRuleTrigger.FINDING_CLOSED ||
                        valueItem.value ===
                          NotificationRuleTrigger.FINDING_SUPPRESSED
                    );
                    return {
                      ...prevAdditionalFormData,
                      triggers: payload['status'] || [],
                      openTicket: hasFindingOpen
                        ? prevAdditionalFormData.openTicket
                        : false,
                      closedTicket: hasFindingClosedOrSurpressed
                        ? prevAdditionalFormData.closedTicket
                        : false,
                    };
                  });
                }}
                defaultValues={{
                  status: additionalFormData.triggers,
                }}
              />
            </InputLabelWrapper>
            {displaySlaBrachedConfig}
            <InputLabelWrapper label="Match Filter">
              <div
                className={`select-chip-group-container notification-rule-filter-chip-group ${
                  errors.properties ? 'input-validation-error-1' : ''
                }`}
              >
                <FilterChipGroup
                  separator="AND"
                  categories={notificationRuleConditionOptions}
                  categoryState={additionalFormData.properties}
                  onChange={(id: string, state: CategoryState) => {
                    setAdditionalFormData((prevAdditionalFormData) => {
                      const updatedProperties = {
                        ...prevAdditionalFormData.properties,
                      };

                      const typedState = state as MultiSelectState;

                      if (typedState.selectedOptions?.length === 0) {
                        if (updatedProperties[id]) delete updatedProperties[id];
                      } else {
                        updatedProperties[id] = state;
                      }

                      return {
                        ...prevAdditionalFormData,
                        properties: updatedProperties,
                      };
                    });
                  }}
                  Components={{
                    AddButtonContent: (
                      <>
                        <span>Add</span>
                        <OpusSvgIcon
                          type={SVG_ICON_TYPES.SORT_DESCENDING_ICON}
                        />
                      </>
                    ),
                  }}
                  addButtonAlignment="right"
                  onRemoveChip={(categoryId: string) => {
                    setAdditionalFormData((prevAdditionalFormData) => {
                      const updatedProperties = {
                        ...prevAdditionalFormData.properties,
                      };

                      if (updatedProperties[categoryId])
                        delete updatedProperties[categoryId];

                      return {
                        ...prevAdditionalFormData,
                        properties: updatedProperties,
                      };
                    });
                  }}
                />
              </div>
              {environmentTypeMismatchError ? (
                <div className="notification-rule-validation-error-text">
                  <span>
                    {translation(
                      `settings.details.notificationRuleEnvironmentMismatchErrorMessage`
                    )}
                  </span>
                </div>
              ) : (
                <></>
              )}
            </InputLabelWrapper>
          </ContentSection>
          <div className="notification-rule-form-panel-form-divider"></div>

          <AccordionSection
            title="Communication"
            rootClassName="notification-rule-content-section"
            sectionIcon={
              <OpusSvgIcon type={SVG_ICON_TYPES.SATELLITE_DISH_ICON} />
            }
            defaultExpanded={isCommunicationConfigured}
          >
            <InputLabelWrapper
              label="Send an Email"
              showCheckbox
              defaultChecked={
                additionalFormData.emails?.length > 0 ||
                additionalFormData.emailRoles?.length > 0
              }
              onToggle={(checked: boolean) => {
                setAdditionalFormData((prevAdditionalFormData) => ({
                  ...prevAdditionalFormData,
                  emails: checked ? selectedEmailList : [],
                  emailRoles: checked ? selectedEmailRolesList : [],
                }));
              }}
              renderBody={(checked: boolean) => (
                <NotificationRuleRoleAutocomplete
                  model="emails"
                  onEmailTeamChange={handleEmailChange}
                  onRoleChange={handleEmailRoleChange}
                  mainOptionList={emailOptionList.options}
                  roleOptionList={UserRoleOptionsList}
                  limitTags={15}
                  mainValues={selectedEmailList}
                  roleValues={selectedEmailRolesList}
                  initialMainSelectedValues={defaultFormValues?.emails}
                  initialRoleSelectedValues={defaultFormValues?.emailRoles}
                  enableCheckbox
                  areOptionsLoaded
                  disabled={!checked}
                  errors={errors} // Your error object
                  checked={checked}
                />
              )}
            ></InputLabelWrapper>

            <InputLabelWrapper
              label="Send a message to"
              showCheckbox
              defaultChecked={
                additionalFormData.teams.length > 0 ||
                additionalFormData.teamRoles?.length > 0
              }
              onToggle={(checked: boolean) => {
                setAdditionalFormData((prevAdditionalFormData) => ({
                  ...prevAdditionalFormData,
                  teams: checked ? selectedTeamList : [],
                  teamRoles: checked ? selectedTeamRolesList : [],
                }));
              }}
              renderBody={(checked: boolean) => (
                <NotificationRuleRoleAutocomplete
                  model="teams"
                  onEmailTeamChange={handleTeamChange}
                  onRoleChange={handleTeamRoleChange}
                  mainOptionList={teamOptionList.options}
                  roleOptionList={TeamRoleOptionsList}
                  limitTags={15}
                  mainValues={selectedTeamList}
                  roleValues={selectedTeamRolesList}
                  initialMainSelectedValues={defaultFormValues?.teams}
                  initialRoleSelectedValues={defaultFormValues?.teamRoles}
                  enableCheckbox
                  areOptionsLoaded
                  disabled={!checked}
                  errors={errors}
                  checked={checked}
                />
              )}
            ></InputLabelWrapper>
            {renderGroupedNotificationSection()}
          </AccordionSection>
          <div className="notification-rule-form-panel-form-divider"></div>
          <AccordionSection
            title="Finding Management"
            rootClassName="notification-rule-content-section"
            sectionIcon={
              <OpusSvgIcon type={SVG_ICON_TYPES.OCTAGON_EXCLAMATION_ICON} />
            }
            defaultExpanded={additionalFormData.suppressFindings}
          >
            <InputLabelWrapper
              label="Suppress Finding"
              showCheckbox
              defaultChecked={additionalFormData.suppressFindings}
              onToggle={(checked: boolean) => {
                setAdditionalFormData((prevAdditionalFormData) => ({
                  ...prevAdditionalFormData,
                  suppressFindings: checked,
                }));
              }}
            ></InputLabelWrapper>
          </AccordionSection>
          <div className="notification-rule-form-panel-form-divider"></div>
          <AccordionSection
            title="Labels"
            rootClassName="notification-rule-content-section notification-rule-content-labels-section"
            sectionIcon={<OpusSvgIcon type={SVG_ICON_TYPES.TAGS_ICON} />}
            defaultExpanded={Boolean(
              defaultFormValues?.labels && defaultFormValues.labels.length > 0
            )}
          >
            <InputLabelWrapper label="Select labels">
              <div
                className="notification-rule-content-labels-section-input"
                onClick={(event) => {
                  setLabelAnchorEl(event.currentTarget);
                }}
              >
                <Autocomplete
                  model="value"
                  optionList={labelItems}
                  loading={labelItemsLoading}
                  onChangeCallBack={(model, value) => {
                    const values = value as Array<AutocompleteOption>;

                    handleLabelsChange(values);
                  }}
                  areOptionsLoaded
                  classes={{
                    root: 'multi-select-field-1',
                    paper: 'multi-select-field-paper-1',
                  }}
                  enableCheckbox
                  placeholder={translation(`labels.pleaseSelectLabels`)}
                  disableDropdown
                  limitTags={4}
                  values={selectedLabels}
                  initialSelectedValues={selectedLabels}
                />
              </div>
            </InputLabelWrapper>

            <BulkActionDropdown
              open={Boolean(labelAnchorEl)}
              handleClose={() => {
                setLabelAnchorEl(null);
              }}
              anchorEl={labelAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              itemType={SystemLabelType.FINDING}
              onSelectLabels={(labels: Array<SystemLabel>) => {
                handleLabelsChange(
                  labels.map((labelItem) => ({
                    value: labelItem.label,
                    label: labelItem.label,
                  }))
                );
              }}
              selectedLabels={selectedLabels.map((selectedLabel) => ({
                label: selectedLabel.value,
              }))}
            />
          </AccordionSection>
          <div className="notification-rule-form-panel-form-divider"></div>

          <AccordionSection
            title="Ticketing"
            rootClassName="notification-rule-content-section"
            sectionIcon={<OpusSvgIcon type={SVG_ICON_TYPES.TICKET_ICON} />}
            defaultExpanded={isTicketingConfigured}
          >
            <InputLabelWrapper
              label="Open Ticket"
              showCheckbox
              defaultChecked={additionalFormData.openTicket}
              disabled={
                !additionalFormData.triggers.find(
                  (trigger) =>
                    trigger.value === NotificationRuleTrigger.FINDING_CREATED
                )
              }
              onToggle={(checked: boolean) => {
                setAdditionalFormData((prevAdditionalFormData) => ({
                  ...prevAdditionalFormData,
                  openTicket: checked,
                }));
              }}
            ></InputLabelWrapper>
            <InputLabelWrapper
              label="Close Ticket"
              showCheckbox
              defaultChecked={additionalFormData.closedTicket}
              disabled={
                !additionalFormData.triggers.find(
                  (trigger) =>
                    trigger.value === NotificationRuleTrigger.FINDING_CLOSED ||
                    trigger.value === NotificationRuleTrigger.FINDING_SUPPRESSED
                )
              }
              onToggle={(checked: boolean) => {
                setAdditionalFormData((prevAdditionalFormData) => ({
                  ...prevAdditionalFormData,
                  closedTicket: checked,
                }));
              }}
            ></InputLabelWrapper>

            <InputLabelWrapper label="Project" isOptional>
              <input
                disabled={
                  !additionalFormData.triggers.find(
                    (trigger) =>
                      trigger.value === NotificationRuleTrigger.FINDING_CREATED
                  )
                }
                type="text"
                className={`text-field-1`}
                {...register('project')}
              ></input>
            </InputLabelWrapper>
          </AccordionSection>
          <div className="notification-rule-form-panel-form-divider"></div>
          <AccordionSection
            title="Workflows"
            rootClassName="notification-rule-content-section notification-rule-content-labels-section"
            sectionIcon={
              <OpusSvgIcon type={SVG_ICON_TYPES.DIAGRAM_PROJECT_ICON} />
            }
            defaultExpanded={Boolean(
              defaultFormValues?.flows && defaultFormValues.flows.length > 0
            )}
          >
            <InputLabelWrapper label="Select a workflow">
              <div className="notification-rule-content-labels-section-input">
                {renderFlowAutocomplete()}
              </div>
            </InputLabelWrapper>
          </AccordionSection>
        </form>
      </div>

      <div className="notification-rule-view-panel-footer">
        <div className="notification-rule-view-panel-footer-validation-error">
          {Object.keys(errors)?.length ? (
            <div className="notification-rule-validation-error-text">
              <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_EXCLAMATION_ICON} />
              <span>
                {environmentTypeMismatchError
                  ? translation(
                      `settings.details.notificationRuleFormValidationErrorMessage`
                    )
                  : translation(
                      `settings.details.notificationRuleFormRequiredErrorMessage`
                    )}
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="notification-rule-view-panel-footer-buttons">
          <Button
            className="opus-secondary-button"
            onClick={() => {
              if (hasFormChanged()) {
                cancelationModalProps?.onOpen &&
                  cancelationModalProps?.onOpen();
              } else {
                cancelationModalProps?.onClose &&
                  cancelationModalProps?.onClose();
                onCancel();
              }
            }}
          >
            Cancel
          </Button>
          <Button
            className="opus-primary-button"
            onClick={() => {
              const isValid = validateFormBeforeSubmit();

              if (isValid) {
                const formValues = getValues();
                const notificationRuleData: NotificationRule =
                  notificationRuleDataHandler.transformNotificationRuleFormDataToPostData(
                    formValues as any,
                    additionalFormData,
                    notificationRuleConditionOptions
                  );
                if (notificationRule) {
                  updateNotificationRule({
                    id: notificationRule.id,
                    ...notificationRuleData,
                  });
                } else {
                  createNotificationRule(notificationRuleData);
                }
              }
            }}
          >
            {createNotificationRuleLoading || updateNotificationRuleLoading
              ? 'Saving'
              : 'Save'}
          </Button>
        </div>
      </div>
    </div>
  );
};
