import { GroupedNotificationIntervalEnum } from 'shared/handlers/notification-rule-data.handler';

export enum NotificationRuleFrequency {
  '1_HOUR' = '1',
  '30_MINUTE' = '30',
  '3_HOURS' = '3',
  '6_HOURS' = '6',
  '12_HOURS' = '12',
  CUSTOM = 'custom',
}
export interface NotificationRuleFrequencyItem {
  value: NotificationRuleFrequency;
  label: string;
  unit: GroupedNotificationIntervalEnum;
}

export const NotificationRuleFrequencyList: NotificationRuleFrequencyItem[] = [
  {
    value: NotificationRuleFrequency['30_MINUTE'],
    label: 'Every 30 minutes',
    unit: 'MINUTES',
  },
  {
    value: NotificationRuleFrequency['1_HOUR'],
    label: 'Every 1 hour',
    unit: 'HOURS',
  },
  {
    value: NotificationRuleFrequency['3_HOURS'],
    label: 'Every 3 hours',
    unit: 'HOURS',
  },
  {
    value: NotificationRuleFrequency['6_HOURS'],
    label: 'Every 6 hours',
    unit: 'HOURS',
  },
  {
    value: NotificationRuleFrequency['12_HOURS'],
    label: 'Every 12 hours',
    unit: 'HOURS',
  },
  {
    value: NotificationRuleFrequency.CUSTOM,
    label: 'Once per day at ...',
    unit: 'DAILY',
  },
];
