import { useAuthUser } from '@frontegg/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress, Skeleton } from '@mui/material';
import { ApplicationTypes } from 'Application/interfaces/applications.enum';
import { useFetchUsersMutation } from 'Auth/store/api';
import Autocomplete from 'Common/components/Autocomplete';
import useCommonSelector from 'Common/utils/use-selector';
import { applicationTypeToTicketApplicationMap } from 'FindingDetails/interfaces/create-ticket';
import {
  AutocompleteOption,
  useCreateMultiFindingTicketMutation,
  useFetchTicketSuggestionMutation,
} from 'FindingDetails/store/api';
import { IntegrationCategory } from 'Integrations/interfaces/IntegrationCategory.enum';
import { IntegrationItem } from 'Integrations/interfaces/IntegrationItem.model';
import { useGetConnectionByCategoryMutation } from 'Integrations/store/api';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getTicketApplicationLabel } from 'Risk/components/TicketCreateForm/TicketCreateForm';
import {
  getAdditionalSearchParams,
  getLabelSearchParams,
  getTagsSearchParams,
  getVulnerabilitySearchParams,
  GridType,
} from 'Risk/store';
import { CommonModalContainer } from 'shared/components/CommonModalContainer';
import { BaseModalProps } from 'shared/components/CommonModalContainer/CommonModalContainer';
import OpusAvatarIcon from 'shared/components/IconComponents/OpusAvatarIcon';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import InputLabelWrapper from 'shared/components/InputLabelWrapper';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import * as yup from 'yup';

export interface MultiFindingsTicketCreateDto {
  gridType: GridType;
  ticketingAppId: TicketingAppId;
  summary: string;
  projectKey?: string;
  assigneeEmail?: string;
  findingIds?: string[] | null;
  appliedFilter?: any;
  creationLink?: string;
}

export enum TicketingAppId {
  JIRA_CLOUD_PLATFORM = 'JiraCloudPlatform',
  JIRA_SERVER_PLATFORM = 'JiraServerPlatform',
  AZURE_DEVOPS = 'AzureDevops',
}

interface MultiFindingTicketModalProps
  extends BaseComponentProps,
    BaseModalProps {
  findingsCount?: number;
  findingIds: Array<string>;
  gridType: GridType;
  additionalFilters?: Record<string, any>;
  createMultiFindingsTicket: (payload: MultiFindingsTicketCreateDto) => void;
  creatingMultiFindingsTicket: boolean;
  createMultiFindingsTicketSuccess: boolean;
}

const multiFindingTicketFormSchema = yup.object().shape({
  ticketingApp: yup.object().required(),
  summary: yup.string().required().min(1),
  project: yup.string().required().min(1).max(255),
  assignee: yup.string().required().min(1),
});

export const MultiFindingTicketModal: FunctionComponent<
  MultiFindingTicketModalProps
> = ({
  isOpen,
  handleClose,
  findingsCount,
  findingIds,
  gridType,
  additionalFilters,
  createMultiFindingsTicket,
  createMultiFindingsTicketSuccess,
  creatingMultiFindingsTicket,
}) => {
  const { t: translation } = useTranslation();

  const authUser = useAuthUser();

  const {
    register,
    setValue: setFormValue,
    watch,
    formState: { errors: formErrors },
    reset,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(multiFindingTicketFormSchema),
    reValidateMode: 'onSubmit',
    mode: 'onSubmit',
    defaultValues: {
      assignee: authUser.email,
      project: '',
      summary: '',
    } as any,
  });

  const additionalSearchParams = useCommonSelector(getAdditionalSearchParams);
  const vulnerabilitySearchParams = useCommonSelector(
    getVulnerabilitySearchParams
  );
  const tagsSearchParams = useCommonSelector(getTagsSearchParams);

  const labelSearchParams = useCommonSelector(getLabelSearchParams);

  const appliedFilter = {
    filter: { ...additionalSearchParams, ...additionalFilters },
    vulnerabilityFilter: vulnerabilitySearchParams,
    tagFilter: tagsSearchParams,
    labelFilter: labelSearchParams,
  };

  const [
    fetchConnections,
    { data: connectionList, isLoading: isConnectionListLoading },
  ] = useGetConnectionByCategoryMutation();

  const [
    fetchTicketSuggestion,
    { data: ticketSuggestion, isLoading: isLoadingTicketSuggestion },
  ] = useFetchTicketSuggestionMutation();

  const ticketingApp = watch('ticketingApp');
  const summary = watch('summary');
  const project = watch('project');
  const assignee = watch('assignee');

  const onAutocompleteValueChange = (
    model: string,
    selection: AutocompleteOption | Array<AutocompleteOption>
  ) => {
    setFormValue(model as any, selection);
  };

  const defaultApplication = useMemo<AutocompleteOption | null>(() => {
    if (connectionList) {
      const connection: IntegrationItem =
        connectionList.find(
          (connection: IntegrationItem) =>
            connection.id === ApplicationTypes.JIRA_CLOUD_PLATFORM
        ) || connectionList[0];

      if (connection) {
        return {
          value: connection.id,
          label: getTicketApplicationLabel(connection.id as any),
          iconUrl: connection.logo,
        };
      }

      return null;
    }

    return null;
  }, [connectionList]);

  useEffect(() => {
    if (createMultiFindingsTicketSuccess) {
      handleClose();
    }
  }, [createMultiFindingsTicketSuccess]);

  useEffect(() => {
    if (defaultApplication) {
      setFormValue('ticketingApp', defaultApplication);
    }
  }, [defaultApplication]);

  useEffect(() => {
    if (ticketSuggestion) {
      setFormValue('project', ticketSuggestion.projectKey);
      setFormValue('assignee', ticketSuggestion.assigneeEmail);
    }
  }, [ticketSuggestion]);

  useEffect(() => {
    if (ticketingApp && findingIds[0]) {
      const applicationType =
        ticketingApp.value as keyof typeof applicationTypeToTicketApplicationMap;
      const ticketApplication =
        applicationTypeToTicketApplicationMap[applicationType];

      fetchTicketSuggestion({
        appId: ticketApplication,
        findingId: findingIds[0],
      });
    }
  }, [ticketingApp?.value, findingIds.join('')]);

  useEffect(() => {
    if (isOpen) {
      fetchConnections(IntegrationCategory.TICKETING);
    }

    return () => {
      reset();
    };
  }, [isOpen]);

  const onSubmit = () => {
    handleSubmit((data) => {
      createMultiFindingsTicket({
        ticketingAppId: data.ticketingApp.value,
        projectKey: data.project,
        summary: data.summary,
        findingIds: findingIds,
        assigneeEmail: data.assignee,
        gridType: gridType,
        appliedFilter:
          additionalFilters && Object.keys(additionalFilters).length
            ? appliedFilter
            : undefined,
        creationLink: window.location.href,
      });
    })();
  };

  const getApplicationIcon = (iconUrl: string) => {
    return <img src={iconUrl} className="application-icon"></img>;
  };

  const renderForm = () => {
    if (isConnectionListLoading) {
      return (
        <div className="multi-finding-ticket-create-form-loading">
          <CircularProgress size={40} />
        </div>
      );
    }

    return (
      <form className="multi-finding-ticket-create-form">
        <InputLabelWrapper label={''}>
          <Autocomplete
            optionList={
              connectionList?.map((connection: IntegrationItem) => {
                return {
                  value: connection.id,
                  label: getTicketApplicationLabel(connection.id as any),
                  iconUrl: connection.logo,
                };
              }) || []
            }
            single
            model="ticketingApp"
            onChangeCallBack={onAutocompleteValueChange}
            classes={{
              root: 'multi-select-field-1 ',
              paper: 'multi-select-field-paper-1',
              inputRoot: formErrors.ticketingApp
                ? 'input-validation-error-1'
                : '',
            }}
            enableCheckbox
            areOptionsLoaded
            loading={isConnectionListLoading}
            getIcon={(option: AutocompleteOption) =>
              getApplicationIcon(option.iconUrl as string)
            }
            initialSelectedValues={ticketingApp}
            values={ticketingApp}
          />
        </InputLabelWrapper>

        <InputLabelWrapper label={'Summary'} endIconTitle="(Required)">
          <input
            type="text"
            className={`text-field-1 ${
              formErrors.summary ? 'input-validation-error-1' : ''
            }`}
            {...register('summary')}
          ></input>
        </InputLabelWrapper>

        <InputLabelWrapper label={'Project'}>
          {isLoadingTicketSuggestion ? (
            <Skeleton height={50} />
          ) : (
            <input
              type="text"
              className={`text-field-1 ${
                formErrors.project ? 'input-validation-error-1' : ''
              }`}
              {...register('project')}
            ></input>
          )}
        </InputLabelWrapper>

        <InputLabelWrapper label={'Assignee'}>
          {isLoadingTicketSuggestion ? (
            <Skeleton height={50} />
          ) : (
            <input
              type="text"
              className={`text-field-1 ${
                formErrors.assignee ? 'input-validation-error-1' : ''
              }`}
              {...register('assignee')}
            ></input>
          )}
        </InputLabelWrapper>
      </form>
    );
  };

  return (
    <CommonModalContainer
      isOpen={isOpen}
      handleClose={handleClose}
      title={translation(`findings.ticket.createMultiFindingsTicket`)}
      components={{
        TitleIcon: <OpusSvgIcon type={SVG_ICON_TYPES.TICKET_ICON} />,
      }}
      rootClassName="multi-finding-ticket-create-modal"
    >
      <div className="multi-finding-ticket-create-container">
        <div className="form-helper-text">
          <OpusSvgIcon type={SVG_ICON_TYPES.INFO_CIRCLE_ICON} />
          <span>
            You are about to create a Multi-Finding Ticket for{' '}
            <b>{findingsCount}</b> findings
          </span>
        </div>
        {renderForm()}

        <div className="multi-finding-ticket-create-footer">
          <Button onClick={handleClose} className="action-close">
            Cancel
          </Button>
          <Button
            className="action-save opus-primary-button"
            onClick={onSubmit}
            disabled={isLoadingTicketSuggestion}
          >
            {creatingMultiFindingsTicket ? 'Creating' : 'Create'}
          </Button>
        </div>
      </div>
    </CommonModalContainer>
  );
};
